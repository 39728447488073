import React, {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';
import {PlaidLink} from 'react-plaid-link';
import useTranslation from '../../../../../i18n/useTranslation';
import Skeleton from 'react-loading-skeleton';
import {showAlert} from '../../../../../common/Alert/util';
import {useDispatch} from 'react-redux';

const ADD_BANK_DEBIT = gql`
  mutation SetBankDebitPaymentMethod(
    $accountId: String!
    $plaidPublicToken: String!
    $institutionId: String!
    $userId: String
  ) {
    setBankDebitPaymentMethod(
      accountId: $accountId
      plaidPublicToken: $plaidPublicToken
      institutionId: $institutionId
      userId: $userId
    ) {
      kind
      validationStatus
      accountInfo {
        bankName
        last4
      }
    }
  }
`;

const AddBankDebit = ({keyPlaid, refetch, userId = null}) => {
  const dispatch = useDispatch();
  const {translate} = useTranslation();

  // MUTACIÓN PARA CAMBIAR EL MÉTODO DE PAGO BANK DEBIT
  const [mutationSetBankDebitData, {loading: loadDebitBank}] = useMutation(ADD_BANK_DEBIT);

  const onSuccess = useCallback(
    (token, metadata) => {
      mutationSetBankDebitData({
        variables: {
          accountId: metadata.accounts[0].id,
          plaidPublicToken: token,
          institutionId: metadata.institution.institution_id,
          userId,  // Puede ser null y se manejará en el resolver
        },
      })
        .then(() => {
          showAlert(
            {
              text: translate('profile.actions.addBankDebit.successAlert'),
              status: 'success',
            },
            dispatch,
          );
          refetch();
        })
        .catch((error) => {
          showAlert(
            {
              text: translate('profile.actions.addBankDebit.errorAlert'),
              status: 'error',
            },
            dispatch,
          );
          console.error(error);
        });
    },
    [mutationSetBankDebitData, userId, refetch, translate, dispatch],
  );

  return keyPlaid ? (
    <PlaidLink
      className="btn-link m-0 p-0"
      token={keyPlaid}
      onSuccess={onSuccess}
      style={{padding: '0', outline: 'none', background: 'none', border: 'none'}}
    >
      {loadDebitBank ? (
        <Skeleton height={60} />
      ) : (
        translate('profile.actions.changeMethod.addBankDebit')
      )}
    </PlaidLink>
  ) : (
    <span className="btn-link text-error disabled m-0 p-0">
      {loadDebitBank ? (
        <Skeleton height={60} />
      ) : (
        translate('profile.actions.changeMethod.addCard')
      )}
    </span>
  );
};

export default AddBankDebit;
