import React, {useState, useEffect, useRef, useMemo} from 'react'
import {
  Button,
  Brand,
  IconArrowDown,
  ConfirmModal,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import * as FileSaver from 'file-saver'
// Sections
import Proposal from './Sections/Proposal'
import Payments from './Sections/Payments'
import Orientation from './Sections/Orientation'
import Congratulations from './Sections/Congratulations'
import Welcome from './Sections/Welcome'

import useMyPlan from './Model/useMyPlan'
import useGetOwnerPlan from './Model/useGetOwnerPlan'
import {useParams, useLocation, useHistory} from 'react-router-dom'
import useCounselingOnboarding from './useCounselingOnboarding'

import printJS from 'print-js'
import useTemplateReducer from '../../reducers/template'
import {useSelector, useDispatch} from 'react-redux'

import {showAlert} from '../../common/Alert/util'
import './index.css'

import useOnboardingRooms from '../../common/OnboardingRooms'
import useTranslation from '../../i18n/useTranslation'
import useUpdateOnboardingStatus from './Model/useUpdateOnboardingStatus'
import firebase from 'firebase/app'
import {useCookies} from 'react-cookie'

const URL =
  process.env.AMAZONS3_MEDIA_URL ||
  'https://s3.eu-west-1.amazonaws.com/media.zinkerz.com/counseling'
// 'https://media.zinkerz.com/counseling'
// const SHORTURL =
/// 5f92deac586824186078a36c/623c90dc5669ff4db850fc63/proposal-non-sign.pdf'

const CounselingOnboarding = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {counselingOnboarding, setData} = useCounselingOnboarding()
  const history = useHistory()
  const {translate} = useTranslation()
  // counselingPlanId from url
  const {counselingPlanId} = useParams()
  const {checkSession, createSession, leaveSession} = useOnboardingRooms()
  // Things to do before unloading/closing the tab

  // Setup the `beforeunload` event listener
  const setupBeforeUnloadListener = (idPlan) => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()
      leaveSession(idPlan)
    })
    window.addEventListener(
      'popstate',
      (ev) => {
        ev.preventDefault()
        leaveSession(idPlan)
      },
      {capture: true},
    )
    setTimeout(() => {
      leaveSession(idPlan)
    }, 5 * 60 * 60 * 1000) 
  }

  const dispatch = useDispatch()
  const {completeUrl, incompleteUrl} = useMemo(() => {
    if (
      counselingOnboarding?.myPlan?.studentUser?.studentUserId &&
      counselingOnboarding?.myPlan?.id
    ) {
      const ext = `/${counselingOnboarding?.myPlan?.studentUser?.studentUserId}/${counselingOnboarding?.myPlan?.id}/proposal-non-sign.pdf`
      return {
        incompleteUrl: `${URL}${ext}`,
        completeUrl: `${URL}${ext}`,
      }
    }
    return {
      incompleteUrl: null,
      completeUrl: null,
    }
  }, [counselingOnboarding])

  const {/** timezone: zone,**/ roles} = useSelector((state) => state.user.info)

  // isAdmin from url
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // si isAdmin es true verifica si los datos del usuario entrante realmente son de administrador
  const isAdmin =
    searchParams.get('isAdmin') === 'true' && roles?.includes('SUPER_ADMIN')

  const meUser = useSelector((state) => state.user.info)
  const isParent = meUser?.roles?.includes('PARENT')
  const {data: dataQuery} = useMyPlan({
    planId: counselingPlanId,
    isAdmin,
  })
  // validar que pueda aprobar el plan
  const userIdOwnerPlan = dataQuery?.myPlan?.studentUser?.studentUserId
  const approveFromParent = meUser?.parentInfo?.children.find(
    ({studentUserId}) => studentUserId === userIdOwnerPlan,
  )
  const {data: dataUserOwnerPlan} = useGetOwnerPlan({
    id: userIdOwnerPlan,
  })
  const [, , removeCookie] = useCookies(['znk-signup'])

  // template reducer
  const {parseTemplateFromApi} = useTemplateReducer()
  useEffect(() => {
    if (dataQuery?.myPlan) {
      if (!['draft', 'sent'].includes(dataQuery?.myPlan?.status)) {
        // plan ya se encuentra agendado
        history.push('/home')
        showAlert(
          {
            text: 'The plan is already scheduled!',
            status: 'error',
          },
          dispatch,
        )
      } else {
        if (
          userIdOwnerPlan &&
          userIdOwnerPlan !== meUser?.id &&
          !approveFromParent &&
          !isAdmin
        ) {
          setIsOpen(true)
        } else {
          const onVerification = () => {
            // crea session vía socket
            createSession(counselingPlanId, 'proposal')
            setupBeforeUnloadListener(counselingPlanId)
            // establece información del plan en counselingOnboarding
            setData({
              ...counselingOnboarding,
              studentUser: dataQuery.myPlan.studentUser,
              councelor: dataQuery.myPlan.councelor,
              paymentItemsConfig: dataQuery.myPlan.paymentItemsConfig,
              tasks:
                parseTemplateFromApi({
                  templateName: 'Template',
                  phases: dataQuery.myPlan.tasks,
                })?.phases || [],
              myPlan: dataQuery?.myPlan,
            })
          }
          const verificationDenied = () => {
            // plan ya se encuentra agendado
            history.push('/home')
            showAlert(
              {
                text: translate('home.onboard.sessionActiveRedirect'),
                status: 'error',
              },
              dispatch,
            )
          }
          const planId = dataQuery?.myPlan?.id || dataQuery?.myPlan?.planId
          checkSession(planId).then(
            (res) => {
              if (res === true) verificationDenied()
              else onVerification()
            },
            (err) => {
              verificationDenied()
            },
          )
        }
      }
    }
    // eslint-disable-next-line
  }, [dataQuery])

  const [updateOnboading] = useUpdateOnboardingStatus(
    () => {},
    () => {},
  )
  const setOnboardingStatus = async (planId, newStatus) => {
    await updateOnboading({variables: {planId, newStatus}})
  }

  // actualiza estado del onboarding a "aprobado"
  const [section, setSectionState] = useState('welcome')

  useEffect(() => {
    const planId =
      counselingOnboarding?.myPlan?.id || counselingOnboarding?.myPlan?.planId
    if (section && planId) {
      if (['welcome', 'proposal', 'payments', 'orientation'].includes(section))
        setOnboardingStatus(planId, section)
      else if (['congratulations'].includes(section))
        setOnboardingStatus(planId, 'approved')
    }
    // eslint-disable-next-line
  }, [section, counselingOnboarding])

  // referencia al campo para firmar (sirve para scrollear)
  const signRef = useRef(null)
  return (
    <div
      className='m-0 p-0 w-100 h-100 d-flex flex-column'
      style={{backgroundColor: 'rgb(249,249,249)', overflowY: 'scroll'}}
    >
      <div
        style={{
          height: 'fit-content',
          position: 'sticky',
          top: '0px',
          backgroundColor: ['congratulations', 'welcome'].includes(section)
            ? 'transparent'
            : 'rgb(249,249,249)',
          zIndex: 100,
        }}
        className='w-100 d-flex flex-row justify-content-between align-content-center'
      >
        <div className='zinkerz'>
          <Brand style={{alignSelf: 'center'}} />
        </div>
        <div className='d-flex flex-row m-0 p-0 mr-2 my-2'>
          {section === 'proposal' && (
            <>
              <Button
                className='w-auto btn-white'
                label='Print'
                size='small'
                onClick={() => {
                  printJS(completeUrl)
                }}
              />
              <Button
                className='w-auto btn-white mx-2'
                label='Download'
                size='small'
                onClick={() => {
                  FileSaver.saveAs(completeUrl, 'proposalNonSign.pdf')
                }}
              />
              <Button
                className='w-auto'
                color='primary'
                onClick={() => {
                  if (signRef?.current) {
                    signRef.current.scrollIntoView()
                  }
                }}
                label={
                  <span className='d-flex flex-row justify-content-between align-items-center'>
                    Sign <IconArrowDown className='ml-2' size={12} />
                  </span>
                }
                size='small'
              />
            </>
          )}
        </div>
      </div>

      <TabContent
        className={`m-0 p-0 ${section === 'welcome' ? 'h-100' : ''}`}
        activeTab={section}
      >
        <TabPane tabId='welcome' className='h-100 background-bubbles'>
          {/* <div style={{
                  // minHeight: '100%',
                  backgroundImage: `url(${beanScheduled})`
                }}> */}
          {isOpen ? (
            <ConfirmModal
              className=''
              classNameActionDiv=''
              classNameConfirm=''
              classNameCancel=''
              loading={false}
              isOpen={isOpen}
              toggle={false}
              title={'Wrong Email'}
              body={
                <div>
                  <p>You are logged in with {meUser.emails[0].address}</p>
                  <p>
                    Please log in using the student email (
                    {dataUserOwnerPlan?.user?.emails[0]?.address})
                    <span>
                      {dataUserOwnerPlan?.user?.studentInfo?.parents[0]?.email
                        ? ' or parent email ' +
                          '(' +
                          dataUserOwnerPlan?.user?.studentInfo?.parents[0]
                            ?.email +
                          ')' +
                          ' associated with this plan.'
                        : '.'}
                    </span>
                  </p>
                </div>
              }
              confirmAction={() => {
                removeCookie('znk-signup')
                firebase.auth().signOut()
              }}
              cancelAction={() => {}}
              confirmText={'Logout'}
              cancelText={null}
              disabledCancel
              backdrop={[false, 'static']}
            />
          ) : (
            <Welcome
              setSection={setSectionState}
              ready={!!counselingOnboarding?.myPlan}
            />
          )}
        </TabPane>
        <TabPane tabId='proposal'>
          <div className='h-100'>
            <Proposal
              setSection={setSectionState}
              signRef={signRef}
              counselingOnboarding={counselingOnboarding}
              setData={setData}
              url={incompleteUrl}
              isParent={isParent}
              // isAdmin={isAdmin}
              // documentInstance={documentInstance}
              // setDocument={setDocument}
            />
          </div>
        </TabPane>
        <TabPane tabId='payments'>
          <Payments
            setSection={setSectionState}
            counselingOnboarding={counselingOnboarding}
            setData={setData}
            defaultPaymentItemsConfig={dataQuery?.myPlan?.paymentItemsConfig}
            isAdmin={isAdmin}
            isParent={isParent}
          />
        </TabPane>
        <TabPane tabId='orientation'>
          <Orientation
            setSection={setSectionState}
            counselingOnboarding={counselingOnboarding}
            setData={setData}
            isParent={isParent}
          />
        </TabPane>

        <TabPane tabId='congratulations' className='h-100 background-bubbles'>
          <Congratulations counselingOnboarding={counselingOnboarding} />
        </TabPane>
      </TabContent>
    </div>
  )
}
export default CounselingOnboarding
