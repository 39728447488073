import React, {Fragment, useState} from 'react'
import DebitPaymentMethod from './Debit'
import CreditCardMethod from './Credit'
import TransferMethod from './Transfer'
import ManualMethod from './Manual'
import {Collapse} from 'reactstrap'
import {IconArrowRight, ListItem, Activity} from '@Knowledge-OTP/znk-ui-components'
import ZelleMethod from './Zelle'

const PaymentMethod = (props) => {
  const {id, kind, clickable, mutation, userId = null, activity, ...otherProps} = props
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <div onClick={() => (clickable ? setOpen((pre) => !pre) : null)}>
        {kind === 'bankDebit' ? (
          <DebitPaymentMethod {...otherProps} isClickable={clickable} />
        ) : kind === 'creditCard' ? (
          <CreditCardMethod {...otherProps} isClickable={clickable} />
        ) : kind === 'bankCredit' ? (
          <TransferMethod
            accountInfo={otherProps.sourceInfo}
            isClickable={clickable}
          />
        ) : kind === 'manual' ? (
          <ManualMethod
            accountInfo={otherProps?.manualPaymentInfo || {}}
            isClickable={clickable}
          />
        ) : kind === 'zelle' ? (
          <ZelleMethod
            accountInfo={otherProps?.manualPaymentInfo || {}}
            isClickable={clickable}
          />
        ) : null}
      </div>
      {clickable ? (
        <Collapse isOpen={isOpen}>
          <ListItem
            className='mx-2 px-2'
            hasHover
            onClick={() => mutation(id, userId)}
            Left={null}
            activity={activity}
            Right={
              <div className='d-flex align-items-center text-secondary'>
                {
                  activity
                  ? <Activity />
                  :
                  (
                    <Fragment>
                      Set as Default
                      <IconArrowRight className='ml-2' />
                    </Fragment>
                  )
                }                
              </div>
            }
          />
        </Collapse>
      ) : null}
    </>
  )
}

export default PaymentMethod
