import React, {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';
import useTranslation from '../../../../../i18n/useTranslation';
import {showAlert} from '../../../../../common/Alert/util';
import {useDispatch} from 'react-redux';
import {Activity} from '@Knowledge-OTP/znk-ui-components';

const SET_ZELLE_PAYMENT_METHOD = gql`
  mutation SetZellePaymentMethod($userId: String) {
    setZellePaymentMethod(userId: $userId) {
      paymentMethod {
        kind
      }
    }
  }
`;

const AddZellePaymentMethod = ({refetch, userId = null}) => {
  const dispatch = useDispatch();
  const {translate} = useTranslation();

  // MUTACIÓN PARA CAMBIAR EL MÉTODO DE PAGO A ZELLE
  const [mutationAddZellePayment, {loading}] = useMutation(SET_ZELLE_PAYMENT_METHOD);

  const onSuccess = useCallback(() => {
    mutationAddZellePayment({variables: {userId}})
      .then(() => {
        showAlert(
          {
            text: translate('profile.actions.addManualPaymentMethod.successAlert'),
            status: 'success',
          },
          dispatch,
        );
        refetch();
      })
      .catch((error) => {
        showAlert(
          {
            text: translate('profile.actions.addManualPaymentMethod.errorAlert'),
            status: 'error',
          },
          dispatch,
        );
        console.error(error);
      });
  }, [mutationAddZellePayment, userId, refetch, dispatch, translate]);

  return (
    <span className='btn-link m-0 p-0' onClick={onSuccess}>
      {loading ? (
        <Activity style={{marginTop: '7px'}} />
      ) : (
        translate('profile.actions.addManualPaymentMethod.zelle')
      )}
    </span>
  );
};

export default AddZellePaymentMethod;
