// 1. Presenta contrato
// 2. Confirmar payment scheduled
// 3. Agendar orientación
import React, {useMemo, useState, useEffect} from 'react'
// Mody Sing
import modySign from '../../../../assets/newModySign.png'
import {
  // Button,
  StickyButton,
  SignInput,
  useWindowDimensions,
  SignGroup,
} from '@Knowledge-OTP/znk-ui-components'
import useUploadPdfBase64 from '../../Model/useUploadPdfBase64'
import useTranslation from '../../../../i18n/useTranslation'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

// const URL = 'http://media.zinkerz.com/counseling/5f92deac586824186078a36c/623c90dc5669ff4db850fc63/proposal-non-sign.pdf'
const Proposal = (props) => {
  const {setSection, signRef, counselingOnboarding, setData, url = null} = props
  const planData = counselingOnboarding?.myPlan

  const student = useMemo(() => {
    if (planData) return planData?.studentUser
    return {}
  }, [planData])
  const {breakWidth} = useWindowDimensions()
  const isIphone14Max = useMemo(() => {
    if (window.screen.height > 900 && window.screen.height < 999) return true
    else return false
  }, [])
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const goToPayments = () => {
    setLoading(false)
    setReady(true)
    setSection('orientation')
  }
  const [uploadStudentSign] = useUploadPdfBase64(
    (res) => {
      // obtener y guardar el url
      setData({
        ...counselingOnboarding,
        studentSignUrl: res?.uploadPdfBase64,
      })
      if (
        counselingOnboarding?.myPlan?.aditionalInfo?.studentType === 'family'
      ) {
        uploadParentSign({
          variables: {
            file: counselingOnboarding?.signParent,
            // TODO : falta el id del padre
            bucket: `media.zinkerz.com/counseling/${counselingOnboarding?.myPlan?.aditionalInfo?.parentUserId}/sign`,
          },
        })
      } else goToPayments()
    },
    (err) => {
      console.error(err)
    },
  )
  const [uploadParentSign] = useUploadPdfBase64(
    (res) => {
      // obtener y guardar el url
      setData({
        ...counselingOnboarding,
        parentSignUrl: res?.uploadPdfBase64,
      })
      goToPayments()
    },
    (err) => {
      console.error(err)
    },
  )

  // página actual
  const [currentPage, setCurrentPage] = useState(1)
  const {translate} = useTranslation()

  // carga del documento
  const [loadingChangePage, setLoadingChangePage] = useState(false)
  useEffect(() => {
    if (!loadingChangePage) setLoadingChangePage(true)
    // eslint-disable-next-line
    }, [currentPage]
  )
  const [completeUrl, setCompleteUrl] = useState(`${url}#page=${currentPage}`)
  useEffect(() => {
    if (loadingChangePage && url) {
      setTimeout(() => {
        setCompleteUrl(`${url}#page=${currentPage}`)
        setLoadingChangePage(false)
      }, 200)
    }
    // eslint-disable-next-line
    }, [loadingChangePage, url]
  )
  const isMobile = useMemo(() => {
    return breakWidth === 'SM'
  }, [breakWidth])

  return (
    <>
      <div
        className={`w-100 d-flex ${
          isMobile ? 'flex-column align-items-center' : 'flex-row'
        } pt-5`}
      >
        <div
          className={`d-flex flex-column pt-4 pl-6 ${
            isMobile ? 'w-100' : 'w-20'
          }`}
        >
          {!isMobile && (
            <div className='d-flex flex-column m-0 p-0'>
              <label
                className={`cursor-pointer ${
                  currentPage === 1 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${1}`, '__blank')
                  } else setCurrentPage(1)
                }}
              >
                {translate('counselingOnboarding.proposal.pagination.start')}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 2 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${2}`, '__blank')
                  } else setCurrentPage(2)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.proposalLetter',
                )}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 3 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${3}`, '__blank')
                  } else setCurrentPage(3)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.paymentPlan',
                )}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 4 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${4}`, '__blank')
                  } else setCurrentPage(4)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.timelineOfServices',
                )}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 5 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${5}`, '__blank')
                  } else setCurrentPage(5)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.responsabilities',
                )}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 6 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${6}`, '__blank')
                  } else setCurrentPage(6)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.ourResponsabilities',
                )}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 7 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${7}`, '__blank')
                  } else setCurrentPage(7)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.studentPledge',
                )}
              </label>
              <label
                className={`cursor-pointer ${
                  currentPage === 8 ? 'text-title' : 'text-gray'
                }`}
                onClick={() => {
                  if (isMobile) {
                    window.open(`${url}#page=${8}`, '__blank')
                  } else setCurrentPage(8)
                }}
              >
                {translate(
                  'counselingOnboarding.proposal.pagination.privacyTerms',
                )}
              </label>
            </div>
          )}
        </div>
        <div
          className={`${
            isMobile ? 'w-90' : 'w-60'
          } d-flex flex-column justify-content-center`}
        >
          <TabContent
            activeTab={loadingChangePage ? 'loading' : 'show'}
            className='w-100'
          >
            <TabPane tabId='loading'>
              <Skeleton count={1} className='w-100' height={700} />
            </TabPane>
            <TabPane tabId='show'>
              {/* <span>{url}</span> */}
              {!loadingChangePage && completeUrl && isMobile ? (
                <p className='text-center'>
                  Your browser does not support PDFs. <br />
                  <span
                    className='btn-link text-primary'
                    onClick={() => {
                      window.open(
                        `https://docs.google.com/viewerng/viewer?url=${completeUrl}`,
                        '__blank',
                      )
                    }}
                  >
                    View PDF
                  </span>
                  .
                </p>
              ) : (
                <object
                  data={completeUrl}
                  type='application/pdf'
                  width='100%'
                  height='700px'
                  view='Fit'
                  style={{
                    // overflowX: 'scroll',
                    overflowY: 'scroll',
                  }}
                >
                  <iframe
                    title={completeUrl}
                    src={completeUrl}
                    width='100%'
                    height='700px'
                    view='Fit'
                    style={{
                      border: 'none',
                      // overflowX: 'scroll',
                      overflowY: 'scroll',
                    }}
                  >
                    <p>
                      Your browser does not support PDFs.
                      <a href={completeUrl}>Download the PDF</a>.
                    </p>
                  </iframe>
                </object>
                // <object
                //   data={completeUrl}
                //   type='application/pdf'
                //   width='100%'
                //   height='700'
                // >
                //   <p>
                //     Alternative text - include a link{' '}
                //     <a href={completeUrl}>to the PDF!</a>
                //   </p>
                // </object>
              )}
            </TabPane>
          </TabContent>
          <div
            className={`w-100 ${
              breakWidth === 'SM' ? '' : 'px-3 py-8'
            } d-flex flex-column align-content-center align-items-center`}
          >
            <div
              ref={signRef}
              className='d-flex flex-column m-0 p-0 align-self-center my-4 mb-8'
              style={{textAlign: 'center'}}
            >
              <span className='h2 text-title' style={{lineHeight: 3}}>
                {translate('counselingOnboarding.proposal.signs.signToAgree')}
              </span>
              <span className='h45 text-gray'>
                {translate('counselingOnboarding.proposal.signs.bySigning')}
              </span>
            </div>
            {counselingOnboarding?.myPlan?.aditionalInfo?.studentType ===
              'family' && (
              <SignInput
                className='mb-5'
                setSign={(sign) => {
                  setData({
                    ...counselingOnboarding,
                    signParent: sign,
                  })
                  setReady(false)
                }}
                title={translate('counselingOnboarding.proposal.signs.parent')}
                name={`${counselingOnboarding?.myPlan?.aditionalInfo?.parentFirstName} ${counselingOnboarding?.myPlan?.aditionalInfo?.parentLastName}`}
              />
            )}

            {breakWidth === 'SM' ? (
              <>
                <SignInput
                  setSign={(sign) => {
                    setData({
                      ...counselingOnboarding,
                      sign,
                    })
                    setReady(false)
                  }}
                  title={translate(
                    'counselingOnboarding.proposal.signs.student',
                  )}
                  name={`${student?.firstName} ${student?.lastName}`}
                />
                <SignInput
                  title={translate(
                    'counselingOnboarding.proposal.signs.zinkerz',
                  )}
                  defaultImage={modySign}
                  name={translate(
                    'counselingOnboarding.proposal.signs.zinkerzAdmin',
                  )}
                  closed
                  showing
                />
              </>
            ) : (
              <SignGroup>
                <SignInput
                  setSign={(sign) => {
                    setData({
                      ...counselingOnboarding,
                      sign,
                    })
                    setReady(false)
                  }}
                  title={translate(
                    'counselingOnboarding.proposal.signs.student',
                  )}
                  name={`${student?.firstName} ${student?.lastName}`}
                />
                <SignInput
                  title={translate(
                    'counselingOnboarding.proposal.signs.zinkerz',
                  )}
                  defaultImage={modySign}
                  name={translate(
                    'counselingOnboarding.proposal.signs.zinkerzAdmin',
                  )}
                  closed
                  showing
                />
              </SignGroup>
            )}
            <StickyButton
              onClickButtonFunction={() => {
                if (ready) {
                  setSection('payments')
                } else {
                  setLoading(true)
                  uploadStudentSign({
                    variables: {
                      bucket: `media.zinkerz.com/counseling/${counselingOnboarding?.studentUser?.studentUserId}/sign`,
                      file: counselingOnboarding.sign,
                    },
                  })
                }
              }}
              disabled={
                !(
                  (counselingOnboarding?.myPlan?.aditionalInfo?.studentType ===
                    'family' &&
                    counselingOnboarding?.signParent &&
                    counselingOnboarding?.sign) ||
                  (counselingOnboarding?.myPlan?.aditionalInfo?.studentType !==
                    'family' &&
                    counselingOnboarding?.sign)
                )
              }
              activity={loading}
              className='my-4'
              bottomDistance={isIphone14Max ? '250px' : '0px'}
              size='large'
              label={
                <span className='mr-8 white-space-nowrap'>
                  {translate('counselingOnboarding.proposal.signs.sendSigned')}
                </span>
              }
              color='counseling'
              check
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Proposal
