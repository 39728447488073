import React, {Fragment, useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import {
  IconUser,
  ModalLayout,
  IconCountry,
  IconBank,
  IconArrowLeft,
  Button,
  IconSignPlus,
  LogoItem,
  EarlyActionBadge,
  EarlyDecisionBadge,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import './collegeModal.css'
import useMyCollegeModal from './useMyCollegeModal'
import SummaryTab from './Summary'
import TasksTab from './Tasks'
import DocumentsTab from './Documents'
import InfoTab from './Info'
import useTranslation from '../../../../i18n/useTranslation'
import ShowUniversityComponent from './Components/ShowUniversity/index'
import useSetMyUniversity from '../../../University/Model/useSetMyUniversity'
import ViewUniversityWebsite from './Components/ViewUniversityWebsite'
import phaseNames from '../../Utils/phaseNames'
import MessageModal from '../ViewTask/Components/Modals/messageModal'

const CollegeModal = (props) => {
  const {isOpen, toggle, setUpdateUniversity, plan} = useMyCollegeModal()
  const universityFreeLimit = 10
  const realCollegesList = useSelector(
    (state) => state?.user?.info?.studentInfo?.universities,
  )

  const college = useSelector(
    (state) => state?.modal?.counseling?.collegeModal?.college,
  )
  // const planStatus = useSelector((state) => state.modal.counseling.planStatus)
  const collegeId = college?.id

  const {translate} = useTranslation()

  const {submit: addUniversity, loading, data} = useSetMyUniversity(() => {})

  // const noInfo = translate('counseling.noInfo')

  let myCollegesFilter = realCollegesList?.filter((x) => x?.id === college?.id)
  // Variable para saber si es "My University" o "University" y renderizar de acuerdo a ello
  let isMyCollege = myCollegesFilter?.length > 0

  const [activeTab, setActiveTab] = useState('summary')
  const [render, setRender] = useState(false)
  const refetchUserData = useSelector((state) => state.user.refetch)
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [message, setMessage] = useState('')

  const addUniversityFn = (id, status) => {
    const bool1 =
      [phaseNames.application, phaseNames.postApplication].includes(status) &&
      realCollegesList?.length >= universityFreeLimit
    const bool2 =
      [
        phaseNames.preApplication,
        phaseNames.sent,
        phaseNames.scheduled,
      ].includes(status) || realCollegesList?.length < universityFreeLimit
    // Algoritmo para revisar que no se agreguen mas de 10 universidades en un plan si esta en Application o Post Application
    if (bool1) {
      setMessage(translate('counseling.modal.contactsupportuniversity'))
      setMessageModalOpen(true)
    } else if (bool2) {
      addUniversity({
        universityId: id,
      })
    }
  }

  useEffect(() => {
    if (collegeId) {
      const filterCollege = realCollegesList?.filter(
        (x) => x.id === college?.id,
      )
      if (filterCollege !== []) {
        setUpdateUniversity({
          ...college,
          early: filterCollege[0]?.early || 'nothing',
          status: filterCollege[0]?.status || 'wishlist',
        })
      }
    }
    // eslint-disable-next-line
  }, [collegeId])

  useEffect(() => {
    if (data) {
      refetchUserData()
      // eslint-disable-next-line
      myCollegesFilter = data?.studentInfo?.universities.filter((x) => x?.id === college?.id)
      // eslint-disable-next-line
      isMyCollege = (myCollegesFilter?.length > 0)
      college?.id &&
        setUpdateUniversity({
          ...college,
          early: myCollegesFilter[0].early,
          status: myCollegesFilter[0].status,
        })
      setRender(!render)
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <ModalLayout
      className='modal-colored modal-sidebar modal-desktop-half college-modal'
      isOpen={isOpen}
      toggle={() => {
        setActiveTab('summary')
        toggle()
      }}
      minHead={
        <span
          className='h3 ml-4'
          // style={{ color: '#2C3F58' }}
        >
          {college?.name}
        </span>
      }
      topHead={
        college?.logo ? (
          isMyCollege ? (
            <LogoItem
              className='mr-2 bg-transparent'
              key={1}
              image={college?.logo}
              status={college?.status}
              onClick={() => {}}
              showTolTip
            />
          ) : (
            <div
              className='d-flex flex-row align-items-start btn-link'
              onClick={toggle}
            >
              <div>
                <IconArrowLeft size={15} color='#8c90ff' />
              </div>
              <div>
                <span
                  className='ml-1'
                  style={{
                    color: '#8c90ff',
                  }}
                >
                  {translate('counseling.back')}
                </span>
              </div>
            </div>
          )
        ) : (
          <Skeleton className='mx-1' count={1} height={110} width={110} />
        )
      }
      underHead={
        college?.logo ? (
          isMyCollege ? (
            // Si es My University muestra esto:
            <div className='mb-2 align-items-center'>
              <div className='mt-4 p-0 d-flex flex-row'>
                <div
                  className='h3 ml-4 align-middle'
                  style={{color: '#2C3F58'}}
                >
                  {college?.name}
                </div>

                {/* Renderiza solo si está marcado como Early Action */}
                {college?.early === 'action' ? (
                  <EarlyActionBadge />
                ) : // Renderiza solo si está marcado como EarlyDecision
                ['decision', 'decision2'].includes(college?.early) ? (
                  <EarlyDecisionBadge
                    edVersion={college?.early || 'decision'}
                  />
                ) : null}
              </div>
              <div className='mt-2 h-100 d-flex flex-row'>
                <div className='d-flex flex-row ml-4'>
                  <span className='mr-5 h45 text-secondary d-flex flex-row align-items-center'>
                    <IconCountry className='text-secondary mr-2' size={14} />{' '}
                    {college?.city}, {college?.stateAbbr}
                  </span>
                </div>
                <span className='mr-5 h45 text-secondary d-flex flex-row align-items-center'>
                  <IconUser className='text-secondary mr-1' size={14} /> ~
                  {(college?.undergraduateSize / 1000).toFixed(1)}K{' '}
                  {translate('counseling.undergraduates')}
                </span>
              </div>
              <ViewUniversityWebsite styles='ml-4' college={college} />
            </div>
          ) : (
            // Si es una University muestra esto:
            <div className='d-flex flex-column mt-3' style={{color: 'black'}}>
              <div>
                <img src={college?.logo} alt='' style={{width: '24%'}} />
              </div>
              <div className='mt-4 h3'>{college?.name}</div>
              <div className='general-university-information'>
                <div>
                  <span className='h45 university-position mb-3'>
                    {college?.rankingsBestColleges &&
                    college?.rankingsBestColleges !== 0
                      ? '#' +
                        college.rankingsBestColleges?.value +
                        ' Best Colleges in America'
                      : null}
                  </span>
                </div>

                <div className='primary-information mt-4 mb-2'>
                  <div className='d-flex flex-row'>
                    <div className='h45 text-secondary d-flex flex-row align-items-center'>
                      <IconBank className='text-secondary mr-2' size={14} />{' '}
                      {college?.typeCategory}
                    </div>
                    <div className='h45 text-secondary d-flex flex-row align-items-center ml-5'>
                      <IconCountry className='text-secondary mr-2' size={14} />{' '}
                      {college?.city}, {college?.stateAbbr}
                    </div>
                  </div>
                  <div className='d-flex flex-row mt-2'>
                    <div className='h45 text-secondary d-flex flex-row align-items-center'>
                      <IconUser className='text-secondary mr-2' size={14} /> ~
                      {(college?.undergraduateSize / 1000).toFixed(1)}K{' '}
                      {translate('counseling.undergraduates')}
                    </div>
                  </div>
                  <ViewUniversityWebsite styles='' college={college} />
                </div>
                <span
                  className='d-block custom-scroll text-secondary'
                  style={{
                    color: '#49596F',
                    height: '150px',
                    'scrollbar-color': '#a3aebe #F8F9FA',
                  }}
                >
                  {college?.shortDescription
                    ? college?.shortDescription
                    : "There's no description"}
                </span>
              </div>
            </div>
          )
        ) : (
          <Skeleton className='mx-1' count={2} height={40} width={110} />
        )
      }
      useRightSection={false}
      // disabledScrollAnimation={false}
    >
      <div className='m-0 p-0'>
        {college?.logo ? (
          isMyCollege ? (
            <Fragment>
              <div>
                <Nav className='znk-scroll-horizontal flex-nowrap mt-3'>
                  <NavItem
                    className='nav-title mr-2 h4'
                    active={activeTab === 'summary'}
                  >
                    <NavLink
                      onClick={() => {
                        setActiveTab('summary')
                      }}
                    >
                      Summary
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className='nav-title mr-2 h4'
                    active={activeTab === 'tasks'}
                  >
                    <NavLink
                      onClick={() => {
                        setActiveTab('tasks')
                      }}
                    >
                      Tasks
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className='nav-title mr-2 h4'
                    active={activeTab === 'documents'}
                  >
                    <NavLink
                      onClick={() => {
                        setActiveTab('documents')
                      }}
                    >
                      Documents
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className='nav-title mr-2 h4'
                    active={activeTab === 'info'}
                  >
                    <NavLink
                      onClick={() => {
                        setActiveTab('info')
                      }}
                    >
                      Info
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className='d-flex flex-row justify-content-between mt-4'>
                  <TabContent activeTab={activeTab} className='px-2 w-100'>
                    <TabPane tabId='summary'>
                      <SummaryTab
                        data={null}
                        loading={null}
                        college={college}
                      />
                    </TabPane>
                    <TabPane tabId='tasks'>
                      <TasksTab college={college} />
                    </TabPane>
                    <TabPane tabId='documents'>
                      <DocumentsTab college={college} />
                    </TabPane>
                    <TabPane tabId='info'>
                      <InfoTab universityData={college} />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Fragment>
          ) : (
            <>
              <ShowUniversityComponent university={college} />
              <Button
                style={{
                  position: 'sticky',
                  bottom: '20px',
                }}
                color='primary'
                className='position-sticky w-100 mb-4'
                activity={loading}
                onClick={() => {
                  // addUniversity({
                  //   universityId: college?.id,
                  // })
                  addUniversityFn(college?.id, plan?.status)
                }}
                label={
                  <div className='d-flex align-items-center znk-check'>
                    <span>
                      {translate('counseling.add')} {college?.name}{' '}
                      {translate('counseling.toMyList')}
                    </span>
                    <IconSignPlus
                      className='text-light add-icon-university'
                      color='#696CFF'
                      style={{transform: 'rotate(45deg)'}}
                    />
                  </div>
                }
              />
            </>
          )
        ) : (
          <div className='d-flex flex-column'>
            {new Array(7).fill(0).map(() => {
              return (
                <Skeleton className='mx-1' count={1} height={30} width={300} />
              )
            })}
          </div>
        )}
        <MessageModal
          isOpen={messageModalOpen}
          toggle={() => setMessageModalOpen(!messageModalOpen)}
          message={message}
          addUniversityWithCharge
        />
      </div>
    </ModalLayout>
  )
}

export default CollegeModal
