import React, { useEffect, useMemo, useState } from 'react'
import {
  ListItem,
  IconRight,
  Button,
  IconCircleBack,
  useWindowDimensions,
  Activity,
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane, Badge } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import ChangePaymentPeriodicity from '../../../Onboarding/components/ChangePaymentPeriodicity'
import ChangePaymentMethod from '../../../Onboarding/components/ChangePaymentMethod'
import useBubblesModalHeader from '../../../../bubbles/useBubblesModalHeader'

import CreditCardModal from '../../../Onboarding/components/CreditCardModal'
import BankTranferModal from '../../../Onboarding/components/BankTranferModal'
import BankCreditModal from '../../../Onboarding/components/BankCreditModal'

import useGetPaymentMethodFromUser from '../../../Onboarding/components/useGetPaymentMethodFromUser'
import useChangePaymentMethod from './useChangePaymentMethod'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import { useQuery, gql } from '@apollo/client'

// model
import useSetManuelPaymentMethod from '../../Model/useSetManualPaymentMethod'
import useSetZellePaymentMethod from '../../Model/useSetZellePaymentMethod'
import useSetBankDebitPaymentMethod from '../../Model/useSetBankDebitPaymentMethod'
import useTranslation from '../../../../i18n/useTranslation'
import ZelleModal from '../../../Onboarding/components/ZelleModal'
import formatNumber from '../../../../helpers/formatNumber'
import useSetCreditCardDefaultPaymentMethod from '../../../Onboarding/Model/useSetCreditCardDefaultPaymentMethod'
import { showAlert } from '../../../../common/Alert/util'
import useApproveCounselingPlan from '../../Model/useApproveCounselingPlan'

const Payments = (props) => {
  const {
    setSection,
    counselingOnboarding,
    setData,
    defaultPaymentItemsConfig,
    isAdmin = false,
    isParent = false,
  } = props
  const pdfProposal = useSelector((state) => state?.modal?.scheduledOrientation?.pdfProposal || '')
  // const [payments, setPayments] = useState(myPlan?.paymentItemsConfig || [])
  const bubblesStyle = useBubblesModalHeader()
 const dispatch = useDispatch()
   const [loading, setLoading] = useState(false)
   const refetchUser = useSelector((state) => state.user.refetch)
  const payments = useMemo(() => {
    if (counselingOnboarding?.paymentItemsConfig)
      return counselingOnboarding.paymentItemsConfig
    return []
  }, [counselingOnboarding])
  const setPayments = (payments) => {
    // actualiza en redux
    setData({
      ...counselingOnboarding,
      paymentItemsConfig: payments,
    })
  }

  const [approvePlan, { loading: loadApprove }] = useApproveCounselingPlan(
    (res) => {
      const response = res?.approveCounselingPlan || { success: true }
      if (response?.success) {
        showAlert(
          { text: 'Plan has been approved successfully!', status: 'success' },
          dispatch,
        )
        // refetch del usuario en redux
        refetchUser()
        setSection('congratulations')
      } else {
        // mutación responde error
        showAlert(
          {
            text: response?.errorMsg?.toString() || 'Error, please try again!',
            status: 'error',
          },
          dispatch,
        )
      }

      setLoading(false)
    },
    (err) => {
      console.error(err)
      showAlert({ text: err?.toString(), status: 'error' }, dispatch)
      setLoading(false)
    },
  )
  const mutateOneTime = (paymentItems = []) => {
    if (paymentItems?.length > 0) {
      const otherFixed = paymentItems.filter(
        ({ type }) => type === 'others-fixed',
      )
      return otherFixed.concat([
        paymentItems
          .filter(({ type }) => type !== 'others-fixed')
          .reduce(
            (newPays, paymentItem) => {
              const newAmount = newPays.amount + (paymentItem?.amount || 0)
              return {
                ...newPays,
                amount: newAmount,
                amountWithoutDiscount: newAmount,
              }
            },
            {
              amount: 0,
              date: moment().toISOString(),
              percentDiscount: 0,
              amountWithoutDiscount: 0,
              description: 'Payment 1',
              type: 'counseling-payment',
            },
          ),
      ])
    }
    return paymentItems
  }

  const mutateTwoTime = (paymentItems = []) => {
    if (paymentItems?.length > 0) {
      const total = paymentItems
        .filter(({ type }) => type !== 'others-fixed')
        .reduce((totalAmount, { amount }) => totalAmount + amount, 0)
      const othersFixed = paymentItems.filter(
        ({ type }) => type === 'others-fixed',
      )
      const defaultPaymentItem = {
        amount: total / 2,
        amountWithoutDiscount: total / 2,
        percentDiscount: 0,
        type: 'counseling-payment',
      }
      return othersFixed.concat([
        {
          ...defaultPaymentItem,
          description: 'Payment 1',
          date: moment().toISOString(),
        },
        {
          ...defaultPaymentItem,
          description: 'Payment 2',
          date: moment().add(1, 'month').toISOString(),
        },
      ])
    }
    return paymentItems
  }
  const mutateMonthly = (paymentItems = []) => {
    if (paymentItems?.length > 0) {
      const total = paymentItems
        .filter(({ type }) => type !== 'others-fixed')
        .reduce((totalAmount, { amount }) => totalAmount + amount, 0)
      return paymentItems.map((paymentItem) => {
        if (paymentItem?.type === 'others-fixed') return paymentItem
        return {
          ...paymentItem,
          amount:
            total /
            paymentItems.filter(({ type }) => type !== 'others-fixed').length,
        }
      })
    }
    return paymentItems
  }
  const [periodicity, setPeriodicity] = useState({
    value: null,
    text: 'Default',
  })
  useEffect(() => {
    switch (periodicity.value) {
      case 'monthly':
        setPayments(mutateMonthly(payments))
        break
      case 'oneTime':
        setPayments(mutateOneTime(payments))
        break
      case 'twoTime':
        setPayments(mutateTwoTime(payments))
        break
      default:
        // recomended
        setPayments(defaultPaymentItemsConfig)
    }
    // eslint-disable-next-line
  }, [periodicity])

  // actualiza la info del usuario en redux (vuelve a ejecutar la query me)
  // información de MI usuario
  const { refetch, info: userInfo } = useSelector((state) => state.user)

  const { paymentMethodKeys, PaymentLabel } = useChangePaymentMethod()

  // monto de la cuota atSigning
  const atSigningAmount = useMemo(() => {
    return payments?.find((pay) => pay?.type === 'others-fixed')?.amount || 0
  }, [payments])

  // actualiza la info del usuario dueño del plan
  // MODO ADMIN
  const {
    data: ownerPaymentMethod,
    refetch: refetchOwnerPaymentMethod,
  } = useGetPaymentMethodFromUser({
    variables: {
      id: counselingOnboarding?.studentUser?.studentUserId,
    },
    skip: !counselingOnboarding?.studentUser?.studentUserId,
  })

  const [paymentMethod, setPaymentMethod] = useState({
    value: 'undefined',
    text: 'No defined',
    __typename: 'undefined',
    cardInfo: {},
  })

  const paymentMethodUser = ownerPaymentMethod?.paymentMethod
  const currentUserPaymentMethod = useMemo(() => {
    // const userPaymentMethod = ownerPaymentMethod?.paymentMethod
    // isAdmin || isParent
    //   ? ownerPaymentMethod?.paymentMethod
    //   : userInfo?.paymentMethod
    // console.log('userPaymentMethod', userPaymentMethod)
    setPaymentMethod({
      value: paymentMethodUser?.id ? paymentMethodUser?.id : paymentMethodUser?.__typename,
      text: paymentMethodUser?.__typename,
      __typename: paymentMethodUser?.__typename,
      cardInfo: paymentMethodUser?.cardInfo || {},
    })
    return paymentMethodUser
    // return isAdmin || isParent ? ownerPaymentMethod?.paymentMethod : userInfo?.paymentMethod
    // eslint-disable-next-line
  }, [paymentMethodUser, ownerPaymentMethod, userInfo])

  const refetchAll = () => {
    if (isAdmin || isParent) refetchOwnerPaymentMethod()
    else refetch()
  }

  // get plaid key
  const { data: dataPlaid } = useQuery(
    gql`
      query {
        getPlaidLink
      }
    `,
  )

  const modalProps = {
    paymentMethod,
    writeMeQuery: () => { },
    setPaymentMethod,
  }
  const [isOpenCredit, setOpenCredit] = useState(false)
  const toggleCredit = () => {
    refetchOwnerPaymentMethod()
    refetchAll()
    setOpenCredit(!isOpenCredit)
  }

  const [isOpenTranfer, setOpenTranfer] = useState(false)
  const toggleTranfer = () => {
    setOpenTranfer(!isOpenTranfer)
  }

  const [isOpenZelle, setOpenZelle] = useState(false)
  const toggleZelle = () => {
    setOpenZelle(!isOpenZelle)
  }

  const [isOpenTranferCredit, setOpenTranferCredit] = useState(false)
  const toggleTranferCredit = () => {
    setOpenTranferCredit(!isOpenTranferCredit)
  }
  // mutacion para método de pago ZELLE
  const [
    setZellePaymentMethod,
    { data: dataZelleModal, loading: loadZelleModal },
  ] = useSetZellePaymentMethod(
    () => {
      // refetchAll()
      refetchOwnerPaymentMethod()
      toggleZelle()
    },
    (err) => {
      console.error(err)
    },
  )
  // mutacion para método de pago BANK TRANFER (NO USD)
  const [
    setManualPaymentMethod,
    { data: dataTranferModal, loading: loadTranferModal },
  ] = useSetManuelPaymentMethod(
    () => {
      refetchOwnerPaymentMethod()
      // refetchAll()
      toggleTranfer()
    },
    (err) => {
      console.error(err)
    },
  )
  const [
    mutationSetBankDebit,
    { loading: loadDebitBank },
  ] = useSetBankDebitPaymentMethod(
    () => {
      refetchOwnerPaymentMethod()
      // refetchAll()
    },
    (err) => {
      console.error(err)
    },
  )

  // mutation for set credit card existing
  const [
    setCreditCardDefaultPaymentMethod,
    { loading: loadingSetExistingCreditCard },
  ] = useSetCreditCardDefaultPaymentMethod(
    () => {
      showAlert({
        text: 'Success',
        status: 'success',
      })
    },
    (error) => {
      showAlert(
        {
          text: `Error setting up manual payment: ${error.message}`,
          status: 'error',
        },
        () => {
          // refetchAll()
        },
      )
    },
  )

  const { breakWidth } = useWindowDimensions()

  const isMobile = breakWidth === 'SM'
  const isTablet = breakWidth === 'MD'

  const { translate } = useTranslation()

  const isCreditCard = useMemo(
    () => currentUserPaymentMethod?.__typename === 'CardPaymentMethod',
    [currentUserPaymentMethod],
  )
  const totalAmount = useMemo(() => {
    return parseFloat(payments.reduce((total, { amount }) => total + amount, 0))
  }, [payments])

  return (
    <div className='d-flex flex-row justify-content-center my-6'>
      <div
        className={`${isMobile ? 'w-100' : isTablet ? 'w-70' : 'w-50'}`}
        style={{
          boxShadow: '1px 5px 15px 5px rgba(0,0,0,0.27)',
          borderRadius: '20px',
        }}
      >
        <div
          className='header d-flex flex-row justify-content-center py-7'
          style={{
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            backgroundColor: 'rgb(50,64,91)',
            ...bubblesStyle,
          }}
        >
          <div
            className={`${isMobile ? 'w-90' : 'w-60'} d-flex flex-column pt-4`}
            style={{ lineHeight: '1.5', marginBottom: '5%' }}
          >
            <div className='w-100 d-flex flex-column justify-content-start mb-4'>
              <div
                onClick={() => {
                  setSection('orientation')
                }}
                className='hover-icon dark'
                style={{
                  width: 'fit-content',
                  left: '-10px',
                  position: 'relative',
                }}
              >
                <IconCircleBack color='white' size={20} />
              </div>
            </div>
            <span className='h1 text-white mb-5'>
              {translate('counselingOnboarding.payments.header.title')}
            </span>
            <span className='h4 text-white mb-5'>
              {translate('counselingOnboarding.payments.header.info')}
            </span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white' /** , minHeight: '500px'**/,
            borderRadius: '20px',
            position: 'relative',
            top: '-5%',
            paddingTop: '5%',
          }}
          className='body d-flex flex-row justify-content-center'
        >
          <div className={`${isMobile ? 'w-90' : 'w-60'} d-flex flex-column`}>
            <ChangePaymentPeriodicity
              primary
              setPeriodicity={({ text, value }) => {
                setPeriodicity({ text, value })
              }}
            >
              <ListItem
                showTopLine={false}
                showBottomLine={false}
                className='pl-0 my-2'
                Left={
                  <div className='d-flex align-items-center text-muted'>
                    <span>
                      <b>
                        {translate(
                          'counselingOnboarding.payments.body.payments',
                        )}
                      </b>
                    </span>
                  </div>
                }
                Right={
                  <span className='text-muted d-flex flex-row align-items-center'>
                    {periodicity?.text}{' '}
                    <div className='hover-icon ml-1'>
                      <IconRight />
                    </div>
                  </span>
                }
              />
            </ChangePaymentPeriodicity>

            <ChangePaymentMethod
              primary
              mutationSetBankDebitData={({ variables }) => {
                // método de pago bank debit
                let newVar = variables
                if (isAdmin || isParent)
                  newVar = {
                    ...variables,
                    userId: counselingOnboarding.studentUser.studentUserId,
                  }
                mutationSetBankDebit({ variables: newVar })
              }}
              paymentMethod={paymentMethod.value}
              setPaymentMethod={setPaymentMethod}
              keyPlaid={dataPlaid?.getPlaidLink || ''}
              writeMeQuery={refetchAll}
              isAdmin={isAdmin || isParent}
              onChange={(method, action) => {
                if (method === paymentMethodKeys.BankDebit && action) action()
                // plaid
                else if (method === paymentMethodKeys.Card) toggleCredit()
                else if (method === paymentMethodKeys.Manual) {
                  setPaymentMethod({
                    value: method,
                    text: 'manual',
                    __typename: paymentMethodKeys.Manual,
                  })
                  setManualPaymentMethod(
                    isAdmin || isParent
                      ? {
                        variables: {
                          userId:
                            counselingOnboarding.studentUser.studentUserId,
                        },
                      }
                      : {},
                  )
                } else if (method === paymentMethodKeys.Zelle) {
                  setPaymentMethod({
                    value: method,
                    text: 'zelle',
                    __typename: paymentMethodKeys.Zelle,
                  })
                  setZellePaymentMethod(
                    isAdmin || isParent
                      ? {
                        variables: {
                          userId:
                            counselingOnboarding.studentUser.studentUserId,
                        },
                      }
                      : {},
                  )
                } else if (!Object.values(paymentMethodKeys).includes(method)) {
                  setPaymentMethod({
                    value: method,
                    text: 'registeredCreditCard',
                    __typename: paymentMethodKeys.Card,
                  })
                  setCreditCardDefaultPaymentMethod({
                    variables: {
                      userId: counselingOnboarding.studentUser.studentUserId,
                      paymentMethodId: method,
                    },
                  })
                }
              }}
              registeredPaymentMethods={
                ownerPaymentMethod?.registeredPaymentMethods || []
              }
            >
              <ListItem
                showTopLine
                showBottomLine={false}
                className='pl-0 my-2'
                Left={
                  <div className='d-flex align-items-center text-muted'>
                    <span>
                      <b>
                        {translate(
                          'counselingOnboarding.payments.body.paymentMethod',
                        )}
                      </b>
                    </span>
                  </div>
                }
                Right={
                  <span className='text-muted d-flex flex-row align-items-center'>
                    {currentUserPaymentMethod &&
                      !loadDebitBank &&
                      !loadZelleModal &&
                      !loadTranferModal &&
                      !loadingSetExistingCreditCard ? (
                      <PaymentLabel current={paymentMethod} />
                    ) : loadDebitBank ||
                      loadTranferModal ||
                      loadZelleModal ||
                      loadingSetExistingCreditCard ? (
                      <Activity />
                    ) : (
                      <Badge
                        color='#FFEEEB'
                        className='my-auto ml-4 mr-0 px-3 mr-1 py-1 badge badge-pill badge-danger p-0'
                        pill
                      >
                        <span className='text-error d-flex flex-row align-items-center'>
                          Add <IconRight className='ml-1 text-error' />
                        </span>
                      </Badge>
                    )}
                    {currentUserPaymentMethod && (
                      <div className='hover-icon ml-1'>
                        <IconRight />
                      </div>
                    )}
                  </span>
                }
              />
            </ChangePaymentMethod>
            <div className='borderTop w-100' />
            <div className='my-5 mx-0'>
              <h3 className='text-title'>
                <b>
                  {translate(
                    'counselingOnboarding.payments.body.paymentScheduled',
                  )}
                </b>
              </h3>
              <div className='m-0 p-0 my-4'>
                {true ? (
                  <>
                    {payments.map((pay, idx) => {
                      // aca cambiar la periodicidad del pago
                      return (
                        <ListItem
                          key={idx}
                          showTopLine={false}
                          className='pl-0 py-1'
                          Left={
                            <div className='d-flex align-items-center text-muted'>
                              <span>
                                {pay?.type === 'others-fixed'
                                  ? 'At signing'
                                  : moment(pay.date).format('MMMM YYYY')}
                              </span>
                            </div>
                          }
                          Right={<span>${`${formatNumber(pay.amount)}`}</span>}
                        />
                      )
                    })}
                    <div className='borderTop my-2' />
                    <TabContent activeTab={isCreditCard ? 'show' : 'nothing'}>
                      <TabPane tabId='show'>
                        <>
                          <ListItem
                            key={-1}
                            showTopLine={false}
                            className='pl-0 py-1'
                            Left={
                              <div className='d-flex align-items-center text-muted'>
                                <span>Subtotal</span>
                              </div>
                            }
                            Right={
                              <span>${`${formatNumber(totalAmount)}`}</span>
                            }
                          />
                          <ListItem
                            key={-1}
                            showTopLine={false}
                            className='pl-0 py-1'
                            Left={
                              <div className='d-flex align-items-center text-muted'>
                                <span>CC Processing Fee (4%)</span>
                              </div>
                            }
                            Right={
                              <span>
                                ${`${formatNumber(totalAmount * (4 / 100))}`}
                              </span>
                            }
                          />
                          <div className='borderTop my-2' />
                        </>
                      </TabPane>
                      <TabPane tabId='nothing' />
                    </TabContent>
                    <ListItem
                      key={-1}
                      showTopLine={false}
                      className='pl-0 py-1'
                      Left={
                        <div className='d-flex align-items-center text-muted'>
                          <span>Total</span>
                        </div>
                      }
                      Right={
                        <span>
                          $
                          {`${formatNumber(
                            totalAmount * ((isCreditCard ? 104 : 100) / 100),
                          )}`}
                        </span>
                      }
                    />
                  </>
                ) : (
                  <Skeleton count={4} />
                )}
              </div>
            </div>
            <Button
              onClick={() => {              
                approvePlan({
                  variables: {
                    parentSign: counselingOnboarding?.parentSignUrl,
                    studentSign: counselingOnboarding?.studentSignUrl,
                    approveInput: {
                      planId: counselingOnboarding?.myPlan?.id,
                      typePayment: counselingOnboarding?.periodicity,
                      paymentItemsConfig: counselingOnboarding?.paymentItemsConfig.map(
                        (paymentItem) => {
                          return {
                            amount: paymentItem?.amount,
                            percentDiscount: paymentItem?.percentDiscount,
                            amountWithoutDiscount: paymentItem?.amountWithoutDiscount,
                            date: paymentItem?.date,
                            description: paymentItem?.description,
                            type:
                              paymentItem?.type === 'others-fixed'
                                ? 'otherFixed'
                                : paymentItem?.type === 'counseling-payment'
                                  ? 'counselingPayment'
                                  : paymentItem?.type,
                          }
                        },
                      ),
                      studentId: counselingOnboarding?.studentUser?.studentUserId,
                      councelorInput: {
                        startDate: moment(counselingOnboarding.orientation?.time)
                          .utc()
                          .toISOString(),
                        endDate: moment(counselingOnboarding.orientation?.time)
                          .utc()
                          .add(15, 'minutes')
                          .toISOString(),
                        councelorId: counselingOnboarding.orientation?.educator?.id,
                        planId: counselingOnboarding?.myPlan?.id,
                        // phase: 'ID',
                        // stepId: 'ID',
                        // taskId: 'ID',
                      },
                      proposal: pdfProposal,
                    },
                  },
                })
              }}
              className='w-100'
              size='large'
              check
              color='counseling'
              disabled={!currentUserPaymentMethod}
              activity={loadApprove || loading}
              label={
                <span className='mr-5'>
                  {isCreditCard
                    ? `${translate(
                      'counselingOnboarding.payments.body.confirmPaymentScheduledCredit',
                    )} $${atSigningAmount * (104 / 100)} ${translate(
                      'counselingOnboarding.payments.body.confirmPaymentScheduledNow',
                    )}`
                    : // `Confirm payments and pay $${totalAmount*(4/100)} now` :
                    translate(
                      'counselingOnboarding.payments.body.confirmPaymentScheduled',
                    )}
                </span>
              }
            />
          </div>
        </div>
      </div>

      <CreditCardModal
        refetchOwner={refetchAll}
        isOpen={isOpenCredit}
        toggle={toggleCredit}
        isAdmin={isAdmin || isParent}
        primary
        studentUserId={counselingOnboarding?.studentUser?.studentUserId}
        registeredPaymentMethods={
          ownerPaymentMethod?.registeredPaymentMethods || []
        }
        {...modalProps}
      />
      <BankTranferModal
        isOpen={isOpenTranfer}
        toggle={toggleTranfer}
        bankData={
          !loadTranferModal
            ? dataTranferModal?.setManualPaymentMethod?.paymentMethod
              ?.manualPaymentInfo
            : {}
        }
        primary
        {...modalProps}
      />
      <ZelleModal
        isOpen={isOpenZelle}
        toggle={toggleZelle}
        recipient={
          dataZelleModal?.setZellePaymentMethod?.paymentMethod?.zellePaymentInfo
            ?.emailToSend
        }
        primary
        {...modalProps}
      />
      <BankCreditModal
        isOpen={isOpenTranferCredit}
        toggle={toggleTranferCredit}
        bankData={
          // !loadCreditModal
          // ? dataCreditModal?.setBankCreditPaymentMethod?.sourceInfo
          // :
          {}
        }
        {...modalProps}
      />
    </div>
  )
}

export default Payments
