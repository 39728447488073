import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ModalHeader, TabContent, TabPane } from 'reactstrap'
import {
  Button,
  IconCheckmark,
  IconClose,
  Modal,
  Avatar,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import PaymentMethod from './PaymentMethod'
import AddCard from './AddCard'
import AddBankDebit from './AddBankDebit'
import AddManualPaymentMethod from './AddManualPaymentMethod'
import AddZellePaymentMethod from './AddZelle'

const ModalCard = (props) => {
  const { breakWidth, isOpen, onClick, childrens } = props
  const { translate } = useTranslation()
  const [page, setPage] = useState('methods')
  const [childIdForCard, setChildIdForCard] = useState(null)
  const [openSections, setOpenSections] = useState({});
  let studentIdForDefaultMethod = null;
  const toggleSection = (studentId) => {
    setOpenSections((prev) => ({
      ...prev,
      [studentId]: !prev[studentId],
    }));
  };
  const GET_USER_LIST = gql`
    query {
      getPlaidLink
      me {
        id
        childrenPaymentMethod {
            studentId
       paymentMethod {
          kind
          ... on CardPaymentMethod {
            cardInfo {
              last4
              brand
              expYear
              expMonth
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
                  registeredPaymentMethods {
          id
          kind
          ... on CardPaymentMethod {
            id
            cardInfo {
              last4
              brand
              expYear
              expMonth
              fingerPrint
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
        }
 
        registeredPaymentMethods {
          id
          kind
          ... on CardPaymentMethod {
            id
            cardInfo {
              last4
              brand
              expYear
              expMonth
              fingerPrint
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
      }
    }
  `
  const { data, methodLoading, refetch } = useQuery(GET_USER_LIST)
  //eslint-disable-next-line
  const [setDefaultMethod, { loading: loadingDefaultMethod }] = useMutation(
    gql`
      mutation setMyDefault($paymentMethodId: String!, $userId: String) {
        setMyDefaultPaymentMethod(paymentMethodId: $paymentMethodId, userId: $userId) {
          id
          paymentMethod {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              zellePaymentInfo {
                emailToSend
              }
            }
          }
          registeredPaymentMethods {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              zellePaymentInfo {
                emailToSend
              }
            }
          }
        }
      }
    `,
    {
      update: (cache, mutationResult) => {
        cache.writeQuery({
          query: GET_USER_LIST,
          data: {
            getPlaidLink: data.getPlaidLink,
            me: mutationResult.data.setMyDefaultPaymentMethod,
          },
        })
      },
    },
  )
  const setMethodAsDefault = (paymentMethodId, studentId) => {
    setDefaultMethod({
      variables: {
        paymentMethodId,
        userId: studentId,
      },
    })
  }
  const currentMethods =
    data && !methodLoading
      ? data.me?.childrenPaymentMethod
        ?.map(child => child.paymentMethod ? { ...child.paymentMethod, studentId: child.studentId } : null)
        .filter(Boolean)
      : [];


  const registeredMethods =
    data && !methodLoading
      ? data.me?.childrenPaymentMethod?.flatMap(child =>
        child.registeredPaymentMethods?.map(method => ({
          ...method,
          studentId: child.studentId
        })) || [] // Si no hay métodos registrados, devuelve un array vacío
      )
      : [];

  const mergedData = childrens.map(child => {
    const paymentMethod = currentMethods.find(
      method => method.studentId === child.studentUserId
    );

    const registeredPaymentMethods = registeredMethods.filter(
      method => method.studentId === child.studentUserId
    );

    return {
      ...child,
      paymentMethod: paymentMethod || null,
      registeredPaymentMethods,
    };
  });

  return (
    <Modal
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'} pl-0`}
      backdropClassName='no-op'
      isOpen={isOpen}
      toggle={onClick}
      Head={
        <ModalHeader
          toggle={onClick}
          close={
            <IconClose className='text-cancel' size={20} onClick={onClick} />
          }
          className='px-0'
        >
          <h2 className='htext' style={{ fontSize: '24px' }}>
            {(() => {
              switch (page) {
                case 'methods':
                  return methodLoading
                    ? '...'
                    : currentMethods[0]
                      ? translate('profile.actions.changeMethod.change')
                      : translate('profile.actions.changeMethod.add')
                case 'addCard':
                  return translate('profile.actions.changeMethod.add')
                default:
                  return null
              }
            })()}
          </h2>
        </ModalHeader>
      }
    >
      <TabContent className='pl-0 ml-0' activeTab={page}>
        <TabPane tabId="methods">
          {mergedData.map((child) => (
            <div key={child.studentUserId} className="container-fluid pl-0 mb-6">
              <div className="d-flex align-items-center mb-3">
                <Avatar
                  name={`${child.firstName} ${child.lastName}`}
                  named={false}
                  uri={child?.avatar ? child?.avatar : null}
                  size='avatar-large'
                />
                <span className="text-title h1 pl-0 ml-3">{child.firstName + ' ' + child.lastName}</span>
              </div>


              <div className="col-12 col-lg-10 pl-0 mt-6">
                <div className="w-100 mb-4 pl-0">
                  <span className="text-title h3 pl-0">
                    {translate('profile.actions.changeMethod.current')}:
                  </span>
                </div>
                {child.paymentMethod ? (
                  <PaymentMethod {...child.paymentMethod} />
                ) : (
                  <div className="flex-grow-1 p-auto">{translate('misc.notFound')}</div>
                )}
              </div>

              {/* Botón de Toggle */}
              <span className="btn btn-link mt-3" onClick={() => toggleSection(child.studentUserId)}>
                {openSections[child.studentUserId] ? translate('profile.actions.changeMethod.hideMethods') : translate('profile.actions.changeMethod.showMethods')}
              </span>

              {/* Contenedor desplegable */}
              {openSections[child.studentUserId] && (
                <div className="container-fluid mt-6 pl-0">
                  <div className="col-12 col-lg-10 pl-0">
                    <div className="w-100 mb-4 d-flex flex-column justify-content-start pl-0">
                      <span className="text-title h3 pl-0">
                        {translate('profile.actions.changeMethod.registered')}:
                      </span>
                      <span className="m-0 p-0 d-flex flex-row w-100 justify-content-end">
                        <span
                          className="btn-link m-0 p-0"
                          onClick={() => {
                            setPage('addCard');
                            setChildIdForCard(child.studentUserId);
                          }}
                        >
                          {translate('profile.actions.changeMethod.addCard')}
                        </span>
                        <span className="mx-2 text-title">/</span>
                        <AddManualPaymentMethod refetch={refetch} userId={child.studentUserId} />
                        {data?.getPlaidLink && (
                          <>
                            <span className="mx-2 text-title">/</span>
                            <AddBankDebit refetch={refetch} keyPlaid={data?.getPlaidLink || ''} userId={child?.studentUserId} />
                          </>
                        )}
                        <span className="mx-2 text-title">/</span>
                        <AddZellePaymentMethod refetch={refetch} userId={child.studentUserId} />
                      </span>
                    </div>
                    {child.registeredPaymentMethods?.length ? (
                      studentIdForDefaultMethod = child.studentUserId,
                      child.registeredPaymentMethods.map(
                        (method, idx) =>
                          method.id !== child.paymentMethod?.id && (
                            <PaymentMethod
                              key={method.id || idx}
                              {...method}
                              clickable
                              // onClick={setChildIdForCard(child.studentUserId)}
                              mutation={setMethodAsDefault}
                              userId={studentIdForDefaultMethod}
                              activity={loadingDefaultMethod}
                            />
                          )
                      )
                    ) : (
                      translate('profile.actions.changeMethod.noRegisteredMethods')
                    )}
                  </div>
                </div>
              )}
              <div >
              <span className="border-bottom d-inline-block" style={{ width: '82%' }}></span>
              </div>
            </div>
          ))}
        </TabPane>
        <TabPane tabId='addCard'>
          <AddCard
            onSuccess={() => {
              refetch()
              setPage('success')
            }}
            goBack={() => setPage('methods')}
            currenMethod={currentMethods.find(
              method => method.studentId === childIdForCard
            )}
            registeredPaymentMethods={registeredMethods}
            userId={childIdForCard}
          />
        </TabPane>
        <TabPane tabId='success'>
          <IconCheckmark size={breakWidth === 'SM' ? 80 : 120} />
          <h2 className='htext mb-8' style={{ marginTop: 48 }}>
            {translate('profile.actions.changeMethod.method')}{' '}
            {currentMethods[0] === null
              ? translate('profile.actions.changeMethod.added')
              : translate('profile.actions.changeMethod.changed')}
          </h2>
          <p className='text-p mb-8'>
            {translate('profile.actions.changeMethod.success')}
          </p>
          <Button
            label={translate('misc.coolThanks')}
            color='primary'
            check
            size='medium'
            onClick={() => setPage('methods')}
            style={{ width: '100%' }}
          />
        </TabPane>
      </TabContent>
    </Modal>
  )
}

export default ModalCard
