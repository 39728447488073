import React, {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';
import useTranslation from '../../../../../i18n/useTranslation';
import {showAlert} from '../../../../../common/Alert/util';
import {useDispatch} from 'react-redux';
import {Activity} from '@Knowledge-OTP/znk-ui-components';

const ADD_MANUAL_PAYMENT_METHOD = gql`
  mutation SetManualPaymentMethod($userId: String) {
    setManualPaymentMethod(userId: $userId) {
      paymentMethod {
        kind
      }
    }
  }
`;

const AddManualPaymentMethod = ({refetch, userId = null}) => {
  const dispatch = useDispatch();
  const {translate} = useTranslation();

  // MUTACIÓN PARA CAMBIAR EL MÉTODO DE PAGO
  const [mutationAddManualPayment, {loading}] = useMutation(ADD_MANUAL_PAYMENT_METHOD);

  const onSuccess = useCallback(() => {
    mutationAddManualPayment({
      variables: {
        userId,  // Puede ser null, lo manejará el resolver en backend
      },
    })
      .then(() => {
        showAlert(
          {
            text: translate('profile.actions.addManualPaymentMethod.successAlert'),
            status: 'success',
          },
          dispatch,
        );
        refetch();
      })
      .catch((error) => {
        showAlert(
          {
            text: translate('profile.actions.addManualPaymentMethod.errorAlert'),
            status: 'error',
          },
          dispatch,
        );
        console.error(error);
      });
  }, [mutationAddManualPayment, userId, refetch, translate, dispatch]);

  return (
    <span className="btn-link m-0 p-0" onClick={onSuccess}>
      {loading ? (
        <Activity style={{marginTop: '7px'}} />
      ) : (
        translate('profile.actions.addManualPaymentMethod.manual')
      )}
    </span>
  );
};

export default AddManualPaymentMethod;
