import React, {useMemo, useState} from 'react'
import {
  Avatar,
  Navbar,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {items, itemsMobile} from './Header/items'
import {educatorItems} from './Header/educatorItems'
import { parentItems, parentItemsMobile } from './Header/parentItems'
import {useHistory, useLocation} from 'react-router'
import {useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton'

import SwitchPlatform from './SwitchPlatform'
import getEnv from '../../helpers/getEnv'

import OptionsButton from '../../Pages/Calendar/Lessons/Modal/Options'

const Layout = (props) => {
  const enviroment = getEnv()
  const history = useHistory()
  const location = useLocation()
  const info = useSelector((state) => state.user.info)
  const [isReset, setIsReset] = useState(null)
  const isEducator =
    info?.educatorInfo &&
    info?.educatorInfo?.isActive &&
    info?.roles?.includes('EDUCATOR')
  const isParent = info?.roles?.includes('PARENT')
  // const isStudent = info?.studentInfo !== null || info?.studentInfo !== undefined
  const {breakWidth} = useWindowDimensions()
  const isOnboarding =
    /^\/onboarding\/.*/.test(location.pathname) ||
    /^\/counseling-onboarding\/.*/.test(location.pathname) ||
    /^\/counseling-proposal\/.*/.test(location.pathname)
  const isCounselingOnboarding =
    /^\/counseling-onboarding\/.*/.test(location.pathname) ||
    /^\/counseling-proposal\/.*/.test(location.pathname)
  const avatar = useMemo(() => {
    if (info?.avatar) {
      setIsReset(isReset === null ? false : !isReset)
      return info.avatar
    }
    // eslint-disable-next-line
  }, [info?.avatar])
  return (
    <div
      className={`w-100 h-100 ${breakWidth === 'SM' ? 'bg-light' : 'bg-light'}`}
    >
      {breakWidth === 'SM' ? (
        <div className='d-flex flex-column align-items-center h-100'>
          <div className='navPublic align-self-start w-100'>
            {!isOnboarding && (
              <>
                <Navbar
                  light={false}
                  expand='xs'
                  className=''
                  navClass=''
                  history={history}
                  isPublic={false}
                  items={isEducator ? educatorItems : isParent ? parentItemsMobile : itemsMobile}
                />
              </>
            )}
          </div>
          <div
            className='bodyPublic w-100 h-100'
            style={
              (isOnboarding || breakWidth === 'SM') && !isCounselingOnboarding
                ? {}
                : {overflowY: 'hidden'}
            }
          >
            {props.children}
          </div>
        </div>
      ) : (
        <div className='d-none d-md-flex h-100'>
          <div className={`${isOnboarding ? 'col-0' : 'col-1'} h-100 p-0`}>
            {!isOnboarding && (
              <Navbar
                light
                expand='md'
                className='d-none d-md-flex flex-column h-100 justify-content-between bg-gradient-secondary'
                navClass='d-flex justify-content-start flex-column'
                FootBrand={
                  <div className='navbar-brand'>
                    {info ? (
                      <span
                        className='d-flex justify-content-between align-items-center mb-2'
                        onClick={() => history.push('/profile')}
                        style={{cursor: 'pointer'}}
                      >
                        <Avatar
                          name={`${info.firstName} ${info.lastName}`}
                          uri={avatar}
                          size='avatar-small'
                          isReset={isReset}
                        />
                        <span className='h4 d-none d-xl-block'>
                          &nbsp;{`${info.firstName}`}
                        </span>
                      </span>
                    ) : (
                      <Skeleton circle={10} />
                    )}
                  </div>
                }
                isPublic={false}
                items={(isEducator ? educatorItems : isParent ? parentItems : items).filter(
                  (item) => item.href !== '/profile',
                )}
              />
            )}
          </div>
          <div
            className={`${
              isOnboarding ? 'col-12' : 'col-11'
            } h-100 bodyPublic p-0`}
            style={
              (isOnboarding || breakWidth === 'SM') && !isCounselingOnboarding
                ? {}
                : {overflowY: 'hidden'}
            }
          >
            {props.children}
          </div>
        </div>
      )}
      <OptionsButton />
      {['beta', 'local'].includes(enviroment) && !isOnboarding && (
        <SwitchPlatform />
      )}
    </div>
  )
}

export default Layout
