import React, {useMemo, useState} from 'react'
import {Button} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import useProcessInvoice from './ProcessInvoice'
import useGetInvoicePayUrl from './GetInvoicePayUrl'
import {useSelector} from 'react-redux'
import BankTranferModal from '../../../../Onboarding/components/BankTranferModal'
import ZelleModal from '../../../../Onboarding/components/ZelleModal'

const Submit = (props) => {
  const userInfo = useSelector((state) => state.user.info)
  const {loading, invoice, refetch, buttonStyle = {}, offline = false} = props
  const {translate} = useTranslation()
  const {loading: processingLoading, submit} = useProcessInvoice(() => {
    refetch()
  })

  const [isZelleModalOpen, setIsZelleModalOpen] = useState(false)
  const toggleZelle = () => setIsZelleModalOpen(!isZelleModalOpen)

  const [isBankTransferModalOpen, setIsBankTransferModalOpen] = useState(false)
  const toggleTransfer = () =>
    setIsBankTransferModalOpen(!isBankTransferModalOpen)

  const openLinkOnNewTab = (url = '') => {
    if (url.length > 0) {
      window.open(url, '_blank').focus()
    }
  }
  const onSuccess = (res) => {
    const url = res?.data?.getInvoicePayUrl || res?.getInvoicePayUrl
    openLinkOnNewTab(url)
  }
  const onError = console.error
  const [
    getPayUrl,
    {loading: loadGetUrl, data: dataStripeUrl},
  ] = useGetInvoicePayUrl(onSuccess, onError, {fetchPolicy: 'no-cache'})

  const buttonColor = useMemo(() => {
    if (offline)
      return invoice?.category === 'counseling' ? 'counseling' : 'primary'
    return invoice.status === 'unPaid' ? 'error' : 'primary'
  }, [invoice, offline])

  return !loading ? (
    <>
      {invoice.status === 'pendingPayment' && !offline ? (
        <span className='h5 text-secondary mb-4'>
          {translate('profile.payments.paymentDetails')}
        </span>
      ) : null}
      {!['canceled', 'void', 'paid'].includes(invoice.status) ? (
        <Button
          style={buttonStyle}
          label={translate('profile.payments.payInvoice')}
          color={buttonColor}
          className='w-100 mb-6'
          activity={processingLoading || loadGetUrl}
          onClick={() => {
            if (offline) {
              if (dataStripeUrl?.getInvoicePayUrl)
                openLinkOnNewTab(dataStripeUrl.getInvoicePayUrl)
              else
                getPayUrl({
                  variables: {
                    invoiceId: invoice.id,
                  },
                }).then(onSuccess, onError)
            } else if (userInfo?.paymentMethod?.kind === 'manual') {
              setIsBankTransferModalOpen(true)
            } else if (userInfo?.paymentMethod?.kind === 'zelle') {
              setIsZelleModalOpen(true)
            } else {
              submit({invoiceId: invoice.id})
            }
          }}
          check
        />
      ) : null}
      {isBankTransferModalOpen && (
        <BankTranferModal
          isOpen={isBankTransferModalOpen}
          toggle={toggleTransfer}
          bankData={userInfo?.paymentMethod?.manualPaymentInfo}
          primary
        />
      )}
      {isZelleModalOpen && (
        <ZelleModal
          isOpen={isZelleModalOpen}
          toggle={toggleZelle}
          recipient={userInfo?.paymentMethod?.zellePaymentInfo?.emailToSend}
          primary={true}
        />
      )}
    </>
  ) : null
}

export default Submit
