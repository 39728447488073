import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  ModalLayout,
  IconArrowLeft,
  Button,
  IconSignPlus,
  IconBank,
  IconCountry,
  IconUser,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import './index.css'
import useSetMyUniversity from '../Model/useSetMyUniversity'
import useTranslation from '../../../i18n/useTranslation'

const ShowUniversity = (props) => {
  const {submit: addUniversity, loading, data} = useSetMyUniversity(() => {})
  const internationalNumberFormat = new Intl.NumberFormat('en-US')
  const noInfo = 'No info'
  const {
    isOpen,
    toggle,
    university,
    universityId /** , addUniversity */,
  } = props
  const {translate} = useTranslation()
  const [universityData, setUniversityData] = useState(null)
  const refetchUserData = useSelector((state) => state.user.refetch)

  useEffect(() => {
    setUniversityData(university)
  }, [university])

  useEffect(() => {}, [universityId, data])

  return (
    <ModalLayout
      modalScrollClassName='h-100 m-0 p-0 px-1'
      isOpen={isOpen}
      toggle={toggle}
      useRightSection
      topHead={
        <div
          className='d-flex flex-row align-items-start btn-link'
          onClick={toggle}
        >
          <div>
            <IconArrowLeft size={15} color='#696CFF' />
          </div>
          <div>
            <span className='text-primary'>VOLVER</span>
          </div>
        </div>
      }
      underHead={
        <div className='d-flex flex-column mt-3' style={{color: 'black'}}>
          <div>
            <img
              src={universityData?.logoImage}
              alt=''
              style={{width: '24%'}}
            />
          </div>
          <div className='mt-4 h3'>{universityData?.name}</div>
          <div className='general-university-information'>
            <div>
              <span className='h6 university-position mb-3'>
                {universityData?.rankingsBestColleges
                  ? '#' +
                    universityData.rankingsBestColleges?.value +
                    ' Best Colleges in America'
                  : noInfo}
              </span>
            </div>

            <div className='primary-information mt-3 mb-2'>
              <div className='d-flex flex-row'>
                <span className='h6 w-25 text-secondary'>
                  <IconBank className='text-secondary mr-1' size={14} />{' '}
                  {universityData?.typeCategory}
                </span>
                <span className='h6 w-75 text-secondary'>
                  <IconCountry className='text-secondary mr-1' size={14} />{' '}
                  {universityData?.city}, {universityData?.stateAbbr}
                </span>
              </div>
              <div className='d-flex flex-row mt-2'>
                <span className='h6 text-secondary'>
                  <IconUser className='text-secondary' size={14} /> ~
                  {(universityData?.undergraduateSize / 1000).toFixed(1)} K{' '}
                  {translate('counseling.undergraduates')}
                </span>
              </div>
            </div>
            <span
              className='d-block custom-scroll text-secondary'
              style={{
                color: '#49596F',
                height: '150px',
                'scrollbar-color': '#a3aebe #F8F9FA',
              }}
            >
              {universityData?.shortDescription
                ? universityData?.shortDescription
                : "There's no description"}
            </span>
          </div>
        </div>
      }
    >
      <div className='statistics-information'>
        <div className='d-flex flex-row'>
          <div className='w-50'>
            <div className='title mb-2'>
              {translate('counseling.acceptanceRate')}
            </div>
            <div className='sub-title'>
              {universityData?.acceptanceRate
                ? Math.round(universityData?.acceptanceRate * 100) + '%'
                : noInfo}
            </div>
          </div>
          <div className='w-50'>
            <div className='title mb-2'>
              {translate('counseling.graduationRate')}
            </div>
            <div className='sub-title'>
              {universityData?.fourYearGraduationRate
                ? Math.round(universityData?.fourYearGraduationRate * 100) + '%'
                : noInfo}
            </div>
          </div>
        </div>
        <div className='d-flex flex-row'>
          <div className='w-50'>
            <div className='title mb-2'>{translate('counseling.satAvg')}</div>
            <div className='sub-title'>
              {universityData?.satAverage ? universityData?.satAverage : noInfo}
            </div>
          </div>
          <div className='w-50'>
            <div className='title mb-2'>{translate('counseling.actAvg')}</div>
            <div className='sub-title'>
              {universityData?.actCumulativeMidpoint
                ? universityData?.actCumulativeMidpoint
                : noInfo}
            </div>
          </div>
        </div>
        <div className='d-flex flex-row'>
          <div className='w-50'>
            <div className='title mb-2'>{translate('counseling.numYears')}</div>
            <div className='sub-title'>
              {universityData?.typeYear ? universityData?.typeYear : noInfo}
            </div>
          </div>
          <div className='w-50'>
            <div className='title mb-2'>{translate('counseling.avgCost')}</div>
            <div className='sub-title'>
              {universityData?.avgCostOfAttendance
                ? '$' +
                  internationalNumberFormat.format(
                    universityData?.avgCostOfAttendance,
                  )
                : noInfo}
            </div>
          </div>
        </div>
      </div>
      <div
        className='d-flex flex-row align-items-end h-100'
        style={{position: 'sticky', bottom: '0px'}}
      >
        <Button
          color='primary'
          className='position-sticky w-100 mb-4'
          activity={loading}
          onClick={() =>
            addUniversity({
              // variables: {
              universityId: university?.collegeUnitId,
              universityName: university?.name,
              collegeId: university?.collegeId,
              // },
            }).then((r) => {
              refetchUserData()
              // close()
            })
          }
          label={
            <div className='d-flex align-items-center znk-check'>
              <span>
                {translate('counseling.add')} {university?.name}{' '}
                {translate('counseling.toMyList')}
              </span>
              <IconSignPlus
                className='text-light add-icon-university'
                color='#696CFF'
                style={{transform: 'rotate(45deg)'}}
              />
            </div>
          }
        />
      </div>
    </ModalLayout>
  )
}

ShowUniversity.defaultProps = {
  universityId: null,
  isOpen: false,
  toggle: () => {},
  addUniversity: () => {},
}

ShowUniversity.propTypes = {
  universityId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  addUniversity: PropTypes.func,
}

export default ShowUniversity
