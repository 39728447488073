import React from 'react';
import { Avatar } from '@Knowledge-OTP/znk-ui-components';
import useTranslation from '../../../../../i18n/useTranslation';

const RenderStudent = ( {currentStudent} ) => {

  const { translate } = useTranslation();
  if (!currentStudent) return null;

  return (
    <div className="d-flex flex-row mt-3 pt-3">
      <Avatar
        name={`${currentStudent.firstName} ${currentStudent.lastName}`}
        named={false}
        uri={currentStudent?.avatar || null}
        size="avatar-medium mr-1"
      />
      <span
        title={`${currentStudent.firstName} ${currentStudent.lastName} (${translate('student.infolesson.student')})`}
        className="align-self-center ellipsis-text"
      >
        {`${currentStudent.firstName} ${currentStudent.lastName} `}
        <small className="text-muted">
          ({translate('student.infolesson.student')})
        </small>
      </span>
    </div>
  );
};

export default RenderStudent;
