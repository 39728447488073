import React, {useState, useEffect} from 'react'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import beanWar from '../../../assets/beanWar.svg'
import LayoutSideRightSection from '../Components/LayoutViewRightSection'
import {useSelector, useDispatch} from 'react-redux'
import {UseMyTeam} from '../../../Model/Users/MyTeam/useMyTeam'
import NeedHelpComponent from './NeedHelp'
import {COUNSELING_MY_TEAM} from '../../../store/user/const'
import rolesNames from '../Utils/rolesNames'
import DocsSection from './Sections/Docs'
import TeamSection from './Sections/Team'
import UniversitiesSection from './Sections/Universities'
import TasksSection from './Sections/Tasks'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

const StudentView = () => {
  //  eslint-disable-next-line
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const studentInfo = useSelector((state) => state.user.info)
  const folderDriveId = studentInfo?.studentInfo?.folderDriveId
  const planId = studentInfo?.studentInfo?.homeNotification?.councelingPlanId
  const reduxData = useSelector((state) => state.modal.counseling)

  const [dataTeam, setDataTeam] = useState([])
  const [isOpen, setOpen] = useState(true)
  const toggle = () => {
    setOpen(!isOpen)
  }

  const style = {
    backgroundImage: `url(${beanWar}), url(${beanWar}), url(${beanWar})`,
    backgroundPosition: '70% -74%, 30% -23%, 88% 52%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed, fixed, fixed',
    backgroundSize: '300px, 200px, 150px',
  }

  const [getMyPlan, {data: dataTeamMyPlan, loading: loadingTeam}] = UseMyTeam()

  useEffect(() => {
    if (planId && planId !== '') {
      getMyPlan({
        variables: {
          planId,
        },
      })
    }
    //  eslint-disable-next-line
  }, [planId])

  useEffect(() => {
    if (dataTeamMyPlan) {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling',
        data: {
          ...reduxData,
          planStatus: dataTeamMyPlan?.myPlan?.status,
        },
      })
      const {aditionalInfo, ...staffPlan} = dataTeamMyPlan.myPlan
      const {avatarCounselor, avatarAdvisor, avatarHead} = aditionalInfo

      const staffArray = [
        {...staffPlan.head, role: rolesNames.head, avatar: avatarHead},
        {
          ...staffPlan.councelor,
          role: rolesNames.councelor,
          avatar: avatarCounselor,
        },
        {
          ...staffPlan.advisor,
          role: rolesNames.advisor,
          avatar: avatarAdvisor,
        },
      ]

      setDataTeam(staffArray)
      dispatch({
        type: COUNSELING_MY_TEAM,
        payload: 'counseling.myTeam',
        data: staffArray,
      })
    }
    // eslint-disable-next-line
  }, [dataTeamMyPlan])

  const [activeTabMobile, setActiveTabMobile] = useState('tasks')
  return breakWidth === 'SM' ? (
    <>
      <div className='d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0 px-4'>
        {/* SHORTCUTS */}
        <span
          onClick={() => {
            setActiveTabMobile('tasks')
          }}
          className={`nav-title ${
            activeTabMobile === 'tasks' ? 'active' : ''
          } m-0 mr-3`}
        >
          Tasks
        </span>
        <span
          onClick={() => {
            setActiveTabMobile('universities')
          }}
          className={`nav-title ${
            activeTabMobile === 'universities' ? 'active' : ''
          } m-0 mx-3`}
        >
          Universities
        </span>
        <span
          onClick={() => {
            setActiveTabMobile('docs')
          }}
          className={`nav-title ${
            activeTabMobile === 'docs' ? 'active' : ''
          } m-0 mx-3`}
        >
          Docs & Exam
        </span>
      </div>
      <TabContent activeTab={activeTabMobile} className='px-4'>
        <TabPane tabId='tasks'>
          <TasksSection planId={planId} dataTeam={dataTeam} />
        </TabPane>
        <TabPane tabId='universities'>
          <UniversitiesSection planId={planId} plan={dataTeamMyPlan?.myPlan} />
        </TabPane>
        <TabPane tabId='docs'>
          <DocsSection planId={planId} folderDriveId={folderDriveId} />
        </TabPane>
      </TabContent>
    </>
  ) : (
    <LayoutSideRightSection
      isOpen={isOpen}
      toggle={toggle}
      styleLeftSection={style}
      leftSide={
        <div
          className='w-100 h-100 py-2 px-6 znk-scroll-horizontal'
          style={{position: 'relative'}}
        >
          <div className='mt-2 mt-md-5 mb-4'>
            {/* UNIVERSITIES */}
            <UniversitiesSection
              planId={planId}
              plan={dataTeamMyPlan?.myPlan}
            />
            {/* UNIVERSITIES FIN */}

            {/* TASKS */}
            <TasksSection planId={planId} dataTeam={dataTeam} />
            {/* TASKS FIN */}
          </div>
        </div>
      }
      rightSide={
        <div className='m-0 p-0 d-flex flex-column p-5 pl-6 h-100 justify-content-between'>
          {/* DOCS & EXAM */}
          <DocsSection planId={planId} folderDriveId={folderDriveId} />
          {/* DOCS & EXAM FIN */}

          <div className='mt-2 p-0 d-flex h-100 flex-column justify-content-between'>
            <TeamSection loadingTeam={loadingTeam} dataTeam={dataTeam} />
            <NeedHelpComponent />
          </div>
        </div>
      }
    />
  )
}

export default StudentView
