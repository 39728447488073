import React, {useEffect, useMemo, useState} from 'react'
import {
  ModalScroll,
  IconClose,
  useWindowDimensions,
  Avatar,
  ListItem,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import {useSelector, useDispatch} from 'react-redux'
import {UpcommingHeaderModal, UpcommingBodyModal} from './upcoming'
import {ScheduledHeaderModal, ScheduledBodyModal} from './scheduled'
import {MissedHeaderModal, MissedBodyModal} from './missed'
import {AttendedHeaderModal, AttendedBodyModal} from './attended'
import moment from 'moment-timezone'
import useCalendarEvent from './useCalendarEvent'
import {showAlert} from '../../../../../common/Alert/util'
import useParseLessonFromQuery from '../../useParseLessonFromQuery'
import VirtualChat from '../../../../VirtualChat'
import UsersListDataLesson from '../../../../VirtualChat/utils/usersListDataLesson'
import collectionType from '../../../../VirtualChat/utils/collectionType'
import RenderStudent from '../Components/renderStudents'

const InfoModal = (props) => {
  const {refetchLessons} = props
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const reduxData = useSelector((state) => state.modal.student.infoLessonModal)
  const {idLesson, isOpen} = reduxData
  const [tabValue, setTabValue] = useState('modals')
  // se obtiene la lección mas próxima
  const {id, studentInfo, timezone} = useSelector((state) => state.user.info)
  const {upcomingLesson} = studentInfo
  const {id: upcomingLessonId} = upcomingLesson ?? {}
  useEffect(() => {
    moment.tz(timezone)
  }, [timezone])

  useEffect(() => {
    moment.tz(timezone)
  }, [timezone])

  useEffect(() => {
    setTabValue(reduxData?.modalType || 'modals')
  }, [reduxData])

  const [getDataLesson, {data: dataLessonQuery}] = useCalendarEvent(
    () => {},
    () => {
      showAlert(
        {
          text: translate('student.infolesson.getDataLessonError'),
          status: 'error',
        },
        dispatch,
      )
    },
  )
  useEffect(() => {
    if (idLesson) {
      getDataLesson({
        variables: {
          calendarEventId: idLesson,
        },
      })
    }
    // eslint-disable-next-line
  }, [idLesson])

  const dataLessonArray = useParseLessonFromQuery(
    dataLessonQuery?.calendarEvent ? [dataLessonQuery.calendarEvent] : [],
    upcomingLessonId,
  )
  const dataLesson =
    dataLessonArray?.length > 0
      ? {
          ...dataLessonArray[0],
          status:
            dataLessonArray[0].status === 'proposed'
              ? 'attended'
              : dataLessonArray[0].status,
        }
      : {}
  const {
    status,
    title,
    substitute,
    educator,
    student,
    studentGroup,
    studentPlan,
    service,
    topic,
    notes,
    aditionalInfo,
  } = dataLesson
  const userData = useSelector((state) => state.user.info)
  const isParent = userData?.roles?.includes('PARENT')
  // eslint-disable-next-line
  const [usersList, setUsersList] = useState(UsersListDataLesson(dataLesson))

  const setDataLessonOnRedux = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoLessonModal',
      data: {...reduxData},
    })
  }
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoLessonModal',
      data: {
        ...reduxData,
        isOpen: !isOpen,
      },
    })
    // }
  }
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }

  const currentEducator = substitute
    ? {...substitute, avatar: aditionalInfo?.substituteAvatar || null}
    : {...educator, avatar: aditionalInfo?.educatorAvatar || null} || {}

  const currentStudent =
    student ||
    studentGroup?.find((stu) => stu.studentUserId === id) ||
    studentPlan ||
    {}
  const myAttendance = currentStudent.attendanceStatus
  const isGroupLesson = dataLesson?.__typename === 'GroupLesson'
  const RenderTitleLesson = () => {
    return (
      <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
        <span className='h2 my-1'>
          <b>{dataLesson && service && `${dataLesson.title}`}</b>
        </span>
        <span className='h4 my-1'>
          {dataLesson &&
            dataLesson.startDate &&
            dataLesson.endDate &&
            `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(
              dataLesson.endDate,
            ).format('hh:mm a')} | ${separateCamelCase(
              moment(dataLesson.startDate).format('MMM'),
            )} ${moment(dataLesson.startDate).format('DD, YYYY')}`}
        </span>
        <span className='h45 my-1 pr-2'>
          {dataLesson &&
            dataLesson.__typename &&
            service &&
            `${separateCamelCase(service.name)} | ${separateCamelCase(
              topic?.name,
            )} | ${separateCamelCase(
              dataLesson?.location?.type || 'No defined',
            )} | ${separateCamelCase(
              dataLesson.__typename?.replace('Lesson', ''),
            )}`}
        </span>
      </div>
    )
  }
  const RenderEducator = () => {
    return (
      <ListItem
        showBottomLine
        showTopLine={false}
        classNameLeft='ellipsis-text'
        classNameRight='flex-grow-2'
        Left={
          <div>
          <div className='d-flex flex-row'>
            <Avatar
              name={
                currentEducator &&
                `${currentEducator.firstName} ${currentEducator.lastName} `
              }
              named={false}
              uri={currentEducator?.avatar || null}
              size='avatar-medium mr-1'
            />
            <span
              title={
                currentEducator &&
                `${currentEducator.firstName} ${
                  currentEducator.lastName
                } (${translate('student.infolesson.educator')})`
              }
              className='align-self-center ellipsis-text'
            >
              {currentEducator &&
                `${currentEducator.firstName} ${currentEducator.lastName} `}
              <small className='text-muted'>{`(${translate(
                'student.infolesson.educator',
              )})`}</small>
            </span>
          </div>
          {isParent && 
              <RenderStudent currentStudent={currentStudent} />
            }
          </div>
        }
        Right={
          <div className='d-flex flex-column m-0 p-0'>
            <IconArrowRight />
            {isParent && <IconArrowRight className='mt-7'/>}
          </div>
        }
      />
    )
  }
  const RenderLessonNotes = () => {
    return (
      <div className='d-flex flex-column my-6'>
        <span className='text-title h4'>
          {translate('student.infolesson.sendnotes.title')}
        </span>
        {notes === null || notes === undefined || notes.status === 'draft' ? (
          <>
            <span className='h45 text-gray mt-4'>
              {translate('student.infolesson.sendnotes.waitingeducatornotes')}
            </span>
          </>
        ) : (
          <>
            <span className='text-title h45'>{`${translate(
              'modal.sendnotes.sentOn',
            )} ${moment(notes?.sentTime).format('DD MMMM, YYYY')}`}</span>
            {notes?.educatorNotes?.hadHw && (
              <div className='d-flex flex-row my-3 h45'>
                <div
                  className='d-flex flex-column mr-2'
                  style={{width: '60%'}}
                  flex={1}
                >
                  <span className='text-gray' style={{whiteSpace: 'nowrap'}}>
                    {translate('student.infolesson.sendnotes.hwCompleted')}
                  </span>
                  <span>
                    {notes?.educatorNotes?.didHw
                      ? translate('student.infolesson.sendnotes.yes')
                      : 'No'}
                  </span>
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-gray'>
                    {translate('student.infolesson.sendnotes.score')}
                  </span>
                  <span>{notes?.educatorNotes?.score || '-'}</span>
                </div>
              </div>
            )}
            {notes?.newHw && (
              <div className='d-flex flex-row my-3 h45'>
                <div
                  className='d-flex flex-column mr-2'
                  style={{width: '60%'}}
                  flex={1}
                >
                  <span className='text-gray' style={{whiteSpace: 'nowrap'}}>
                    {translate('student.infolesson.sendnotes.hwAssigned')}
                  </span>
                  <span>{notes?.educatorNotes?.descriptionHw}</span>
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-gray'>
                    {translate('student.infolesson.sendnotes.duedate')}
                  </span>
                  <span>
                    {moment(notes?.educatorNotes?.dueDateHw).format(
                      'DD-MM-YYYY',
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className='d-flex flex-column my-3 h45'>
              <span className='text-gray'>
                {translate('student.infolesson.sendnotes.acqknowledge')}
              </span>
              <span>{notes?.educatorNotes?.acqKnowledge}</span>
            </div>
            {!isGroupLesson && (
              <div className='d-flex flex-column my-3 h45'>
                <span className='text-gray'>
                  {translate('student.infolesson.sendnotes.studenteffort')}
                </span>
                <span>{notes?.educatorNotes?.studentEffort}</span>
              </div>
            )}
            {notes?.others && (
              <div className='d-flex flex-column my-3 h45'>
                <span className='text-gray'>
                  {translate('student.infolesson.sendnotes.othercomments')}
                </span>
                <span>{notes?.educatorNotes?.others}</span>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
  const filterStatus = useMemo(() => {
    // status parseado según el estado del attendance del estudiante y/o su rating de la lección
    // se usa para elegir que body y header se usa en el modal
    // DESCARTA ESTADO incomplete POR LO QUE SOLO PUEDE RETORNAR upcoming, scheduled, attended o missed
    return currentEducator?.attendanceStatus === 'missed'
      ? 'missed'
      : currentStudent.rating === null && // no has valorado la lección
        status !== 'scheduled' && // lección está incompleta o upcoming/inprogress
        myAttendance === 'attended' // estudiante si estuvo en la lección
      ? 'upcoming' // se muestra en azul
      : ['upcoming', 'scheduled'].includes(status)
      ? status // upcomming (azul) o scheduled (gris)
      : ['pending', 'missed'].includes(myAttendance) // estudiante faltó a la lección
      ? 'missed' // etiqueta roja "missed"
      : 'attended' // etiqueta verde "attended"
    // eslint-disable-next-line
  }, [status, currentStudent, myAttendance])

  return (
    <ModalScroll
      isOpen={isOpen && dataLesson?.id}
      toggle={toggle}
      modalScrollClassName='h-100'
      className={`h-100 modal-colored modal-${
        breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
      }  fixed-head-modal ${filterStatus}-modal`}
      MinHead={
        <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
          <div className='d-flex flex-row align-items-center'>
            <span>{`${title}`}</span>
          </div>
          <div className='d-flex flex-row align-items-center'>
            <IconClose color='#D7E1EA' onClick={toggle} />
          </div>
        </div>
      }
      Head={
        <div className='modal-header d-flex flex-column pb-2 pt-3 px-7 m-0'>
          {filterStatus === 'scheduled' ? (
            <ScheduledHeaderModal
              RenderTitleLesson={RenderTitleLesson}
              dataLesson={dataLesson}
              toggle={toggle}
            />
          ) : filterStatus === 'upcoming' ? (
            <UpcommingHeaderModal
              RenderTitleLesson={RenderTitleLesson}
              dataLesson={dataLesson}
              toggle={toggle}
              currentStudent={currentStudent}
              setDataLessonOnRedux={setDataLessonOnRedux}
            />
          ) : filterStatus === 'missed' ? (
            <MissedHeaderModal
              RenderTitleLesson={RenderTitleLesson}
              dataLesson={dataLesson}
              toggle={toggle}
              currentEducator={currentEducator}
            />
          ) : (
            <AttendedHeaderModal
              RenderTitleLesson={RenderTitleLesson}
              dataLesson={dataLesson}
              toggle={toggle}
              currentEducator={currentEducator}
            />
          )}
        </div>
      }
    >
      <div className='modal-body d-flex flex-column py-2 px-7 m-0 h-100'>
        <TabContent activeTab={tabValue} className='h-100'>
          <TabPane
            tabId='chat'
            className={`h-100 ${
              tabValue === 'chat' ? 'd-flex flex-column' : ''
            }`}
          >
            <VirtualChat
              {...dataLesson}
              active={tabValue === 'chat'}
              collectionType={collectionType[dataLesson?.__typename]}
              usersList={usersList}
              collection={dataLesson}
              actualUserId={id}
              RenderLessonNotes={RenderLessonNotes}
              RenderEducator={RenderEducator}
              dataLesson={dataLesson}
              toggle={toggle}
              backArrow={() => {
                setTabValue('modals')
              }}
              currentStudent={currentStudent}
              currentEducator={currentEducator}
              refetchLessons={refetchLessons}
              setDataLessonOnRedux={setDataLessonOnRedux}
              setTabValue={setTabValue}
            />
            {/* {tabValue === 'chat' && (
              
            )} */}
          </TabPane>
          <TabPane tabId='modals'>
            {filterStatus === 'scheduled' ? (
              <ScheduledBodyModal
                RenderEducator={RenderEducator}
                dataLesson={dataLesson}
                toggle={toggle}
                setDataLessonOnRedux={setDataLessonOnRedux}
                refetchLessons={refetchLessons}
                currentEducator={currentEducator}
                currentStudent={currentStudent}
              />
            ) : filterStatus === 'upcoming' ? (
              <UpcommingBodyModal
                RenderLessonNotes={RenderLessonNotes}
                RenderEducator={RenderEducator}
                dataLesson={dataLesson}
                toggle={toggle}
                currentStudent={currentStudent}
                currentEducator={currentEducator}
                refetchLessons={refetchLessons}
                setDataLessonOnRedux={setDataLessonOnRedux}
                setTabValue={setTabValue}
                isOpen={isOpen}
              />
            ) : filterStatus === 'missed' ? (
              <MissedBodyModal
                RenderLessonNotes={RenderLessonNotes}
                RenderEducator={RenderEducator}
                dataLesson={dataLesson}
                toggle={toggle}
                currentEducator={currentEducator}
                currentStudent={currentStudent}
                setTabValue={setTabValue}
              />
            ) : (
              <AttendedBodyModal
                RenderLessonNotes={RenderLessonNotes}
                RenderEducator={RenderEducator}
                dataLesson={dataLesson}
                toggle={toggle}
                currentEducator={currentEducator}
                currentStudent={currentStudent}
                setTabValue={setTabValue}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </ModalScroll>
  )
}

export default InfoModal
