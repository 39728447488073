import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  ListItem,
  IconCreditCard,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import ModalCardForParent from './modalCardForParent'

const ParentPaymentModalForm = (props) => {
  const {paymentMethod, action, isProfileView = true, handleModalClose=false} = props
  const info = useSelector((state) => state.user.info)
  //const childrensPayments = info?.parentInfo?.children.filter(child => child.managePayments);
  const childrensPayments = info?.parentInfo?.children
  const [isOpen, setOpen] = useState(false)
  const {translate} = useTranslation()
  const toggle = () => setOpen((open) => !open)
  const {breakWidth} = useWindowDimensions()
  const handleClose = () => {
    setOpen(false)
    if (handleModalClose) {
      handleModalClose()
    }
  }
  useEffect(() => {
    if (action && (action === 'addpayment' || action === 'addmethodpaymentfromprofile')) {
      toggle()
    }
  }, [action])
  return (
    <span>
      {action !== 'addmethodpaymentfromprofile' && (
        
            <ListItem
              onClick={toggle}
              showBottomLine={isProfileView}
              hasHover
              hasColorHover
              Left={
                <span className='d-flex align-items-center'>
                  {isProfileView && <IconCreditCard size={16} className='mr-3' />}
                  <span className='text-black'>
                    {paymentMethod === null
                      ? translate('profile.actions.changeMethod.add')
                      : translate('profile.actions.changeMethod.change')}
                  </span>
                </span>
              }
            />
          
          
      )}
      <ModalCardForParent breakWidth={breakWidth} isOpen={isOpen} onClick={handleClose} childrens={childrensPayments}/>

    </span>
  )
}

ParentPaymentModalForm.propTypes = {}

export default ParentPaymentModalForm
