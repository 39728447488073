import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {IconEdit, ListItem} from '@Knowledge-OTP/znk-ui-components'
import {UncontrolledCollapse} from 'reactstrap'
import ChangePassword from './ChangePassword'
import ChangeTimezone from './ChangeTimezone'
import PaymentModalForm from '../../Modal/ChangePM'
import useTranslation from '../../../../i18n/useTranslation'
import ChangeNotifications from './ChangeNotifications'
import {useDispatch} from 'react-redux'
import EmploymentDetails from './ChangeEmploimentDetails'
import EducatorAvailability from './EducatorAvailability'
import Logout from './Logout'
import Accounts from './AccountsModal'
import ParentPaymentModalForm from '../../Modal/ChangePM/parentPaymentMethods'

const ProfileActions = (props) => {
  const roles = props.roles ?? []
  const isParent = roles?.includes('PARENT')
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const educatorInfo = props.educatorInfo ?? {}
  let hasParentInfo = false
  if (
    Array.isArray(props?.studentInfo?.parents) &&
    props?.studentInfo?.parents.length > 0
  ) {
    hasParentInfo = true
  }

  const user = useMemo(() => {
    let isStudent = false
    let isEducator = false
    if (roles) {
      if (roles.includes('STUDENT')) {
        isStudent = true
      }
      if (roles.includes('EDUCATOR') && educatorInfo.isActive) {
        isEducator = true
      }
      return {
        isStudent,
        isEducator,
      }
    }
    return {
      isStudent: false,
      isEducator: false,
    }
  }, [roles, educatorInfo])
  return (
    <div className='d-flex flex-column mt-2'>
      <ListItem
        id='toggler'
        className='w-100'
        showTopLine
        showBottomLine
        hasHover
        hasColorHover
        Left={
          <span className='d-flex align-items-center'>
            <IconEdit size={16} className='mr-3' />
            <span className='text-black'>
              {translate('profile.actions.editProfile')}
            </span>
          </span>
        }
      />
      <UncontrolledCollapse toggler='#toggler'>
        <ListItem
          className='m-0 py-3 px-6 w-100 font-weight-light'
          showTopLine
          showBottomLine
          hasHover
          Left={translate('profile.actions.ddEditProfile')}
          Right={' '}
          onClick={() =>
            dispatch({
              type: 'TOGGLE_MODAL_ACTION',
              payload: 'profile.personal',
            })
          }
        />
        {user.isStudent && (
          <ListItem
            className='m-0 py-3 px-6 w-100 font-weight-light'
            showBottomLine
            hasHover
            Left={translate('profile.actions.ddEditStudent')}
            Right={' '}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_MODAL_ACTION',
                payload: 'profile.studentInfo',
              })
            }
          />
        )}
        {hasParentInfo && (
          <ListItem
            className='m-0 py-3 px-6 w-100 font-weight-light'
            showBottomLine
            hasHover
            Left={translate('profile.actions.editFamily')}
            Right={' '}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_MODAL_ACTION',
                payload: 'profile.familyInfo',
              })
            }
          />
        )}
      </UncontrolledCollapse>
      {user.isEducator && <EmploymentDetails {...props} />}
      {user.isEducator && <EducatorAvailability />}
      <ChangePassword />
      {user.isStudent && <PaymentModalForm {...props} />}
      {isParent && <ParentPaymentModalForm  {...props} />}
      {user.isStudent && <ChangeNotifications {...props} />}
      <ChangeTimezone {...props} />
      {user.isStudent && <Accounts {...props} />}
      <Logout />
    </div>
  )
}

ProfileActions.propTypes = {
  userType: PropTypes.number,
}
ProfileActions.defaultProps = {
  userType: 1,
}

export default ProfileActions
