import React, {useState, useMemo, useEffect} from 'react'
import {
  ListItem,
  Button,
  IconRight,
  useWindowDimensions,
  IconBrand,
  StickyButton,
  Activity,
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import PaymentSchedule from '../../components/PaymentSchedule'
import CompleteProfile from '../../components/CompleteProfile'
import ChangePaymentPeriodicity from '../../components/ChangePaymentPeriodicity'
import ChangePaymentMethod from '../../components/ChangePaymentMethod'
import useTranslation from '../../../../i18n/useTranslation'
import {gql, useMutation, useQuery} from '@apollo/client'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import CreditCardModal from '../../components/CreditCardModal'
import BankTranferModal from '../../components/BankTranferModal'
import BankCreditModal from '../../components/BankCreditModal'
import {GetFeeCountFromRangeDate} from '../../utils'
import * as moment from 'moment-timezone'
import useGetPaymentMethodFromUser from '../../components/useGetPaymentMethodFromUser'
import ZelleModal from '../../components/ZelleModal'
import useSetZellePaymentMethod from '../../../CounselingOnboarding/Model/useSetZellePaymentMethod'
import ZelleIcon from '../../../../assets/zelle-icon.png'
import iconVisa from '../../../../assets/card-visa.png'
import iconMastercard from '../../../../assets/card-mastercard.png'
import iconDiners from '../../../../assets/card-diners.png'
import iconDiscover from '../../../../assets/card-discover.png'
import IconCardUndefined from '../../../../assets/card-undefined.png'
import iconAmericanExpress from '../../../../assets/card-amex.png'
import bankIcon from '../../../../assets/bank.png'
import creditCardIcon from '../../../../assets/credit-card-icon.png'
import useUpdateOnboardingStatus from '../../Model/useUpdateOnboardingStatus'
import useSendWspWhenFirstPlanIsApproved from '../../Model/useSendWspWhenFirstPlanIsApproved'
import {showAlert} from '../../../../common/Alert/util'
// import SetStudentPhoneModal from '../../components/setStudentPhone'
// import useSetStudentPhoneModal from '../../components/setStudentPhone/useSetStudentPhoneModal'
import formatNumber from '../../../../helpers/formatNumber'
import useSetCreditCardDefaultPaymentMethod from '../../Model/useSetCreditCardDefaultPaymentMethod'

const TERMS_URL =
  'https://media.zinkerz.com/general/Live+Prep+Terms+%26+Conditions.pdf'
const paymentMethodKeys = {
  Card: 'CardPaymentMethod',
  BankDebit: 'BankDebitPaymentMethod',
  Manual: 'ManualPaymentMethod',
  Zelle: 'ZellePaymentMethod',
  // BankCredit: 'BankCreditPaymentMethod',
}

export const ConfirmPaymentHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <div className='m-0 p-0'>
      <IconBrand size={80} color='white' />
      <div>
        <h1 className='mb-4'>
          <b>{translate('home.onboard.approve.confirm.confirmPayment')}</b>
        </h1>
        <div className='my-2 d-flex flex-column'>
          <h3>
            {translate('home.onboard.approve.confirm.confirmPaymentsubtitle')}
          </h3>
        </div>
      </div>
    </div>
  )
}

export const ConfirmPaymentBody = (props) => {
  const {isAdmin} = props
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {refetch} = useSelector((state) => state.user)
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const mediumModal = useSelector((state) => state.modal.onboarding.mediumModal)
  const paymentItemsConfig = dataPlan?.paymentItemsConfig
  const infoUser = useSelector((state) => state.user.info)
  const isParent = infoUser?.roles?.includes('PARENT')
  // abre modal para ingresar teléfono del estudiante
  // const {open: openSetStudentModal} = useSetStudentPhoneModal()
  const [sendWsp, {loading: loadWsp}] = useSendWspWhenFirstPlanIsApproved(
    () => {
      showAlert({text: 'Wsp sent!', status: 'success'}, dispatch)
    },
    (err) => {
      showAlert({text: err?.toString(), status: 'error'}, dispatch)
    },
  )
  const [updateOnboarding] = useUpdateOnboardingStatus(
    () => {},
    (err) => {
      console.error(err.toString())
    },
  )
  const updateOnboardingStatus = async (planId, newStatus = 'confirm') => {
    await updateOnboarding({
      variables: {planId, newStatus},
    })
  }

  const [paymentMethod, setPaymentMethod] = useState({
    value: 'undefined',
    text: 'No defined',
    typename: 'undefined',
  })
  // método de pago del estudiante para cuando se agenda como administrador
  const {
    data: ownerPaymentMethod,
    refetch: refetchOwnerPaymentMethod,
  } = useGetPaymentMethodFromUser({
    variables: {id: dataPlan?.studentUserId},
    skip: !dataPlan?.studentUserId,
  })
  const paymentMethodUser = ownerPaymentMethod?.paymentMethod
    // isAdmin || isParent
    //   ? ownerPaymentMethod?.paymentMethod
    //   : infoUser?.paymentMethod
  const currentUserPaymentMethod = useMemo(() => {
    setPaymentMethod({
      value: paymentMethodUser?.id
        ? paymentMethodUser?.id
        : paymentMethodUser?.__typename,
      text: Object.keys(paymentMethodKeys)[paymentMethodUser?.__typename],
      typename: paymentMethodUser?.__typename,
    })
    return paymentMethodUser
    // eslint-disable-next-line
  }, [paymentMethodUser, ownerPaymentMethod, infoUser])

  const [paymentError, setPaymentError] = useState(null)
  const paymentMethodUndefined = useMemo(() => {
    return !Object.values(paymentMethodKeys).includes(
      currentUserPaymentMethod?.__typename,
    )
    // eslint-disable-next-line
  }, [currentUserPaymentMethod, paymentMethodKeys])
  const getIcon = (icon) => {
    switch (icon) {
      case 'visa':
        return iconVisa
      case 'mastercard':
        return iconMastercard
      case 'diners':
        return iconDiners
      case 'amex':
        return iconAmericanExpress
      case 'discover':
        return iconDiscover
      default:
        return IconCardUndefined
    }
  }

  const PaymentLabel = () => {
    let label
    switch (paymentMethod?.typename) {
      case paymentMethodKeys.Card:
        label = (
          <>
            <div>
              {translate('home.onboard.payment.creditCard')}{' '}
              {currentUserPaymentMethod?.cardInfo?.last4}
            </div>
            <img
              src={getIcon(currentUserPaymentMethod?.cardInfo?.brand)}
              width={32}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      case paymentMethodKeys.Manual:
        label = (
          <>
            <div>{translate('home.onboard.payment.transferNonUsd')}</div>
            <img
              src={bankIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      /** case paymentMethodKeys.BankCredit:
        label = `${translate('home.onboard.payment.transferUsd')}`
        break**/
      case paymentMethodKeys.BankDebit:
        label = (
          <>
            <div>
              {translate('home.onboard.payment.debitCard')} -{' '}
              {currentUserPaymentMethod?.accountInfo?.bankName.toUpperCase()}{' '}
              {currentUserPaymentMethod?.accountInfo?.last4}
            </div>
            <img
              src={bankIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      case paymentMethodKeys.Zelle:
        label = (
          <>
            <div>{translate('home.onboard.payment.zelle')}</div>
            <img
              src={ZelleIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      default:
        label = (
          <>
            <div>{translate('home.onboard.payment.nodefined')}</div>
            <img
              src={creditCardIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
    }
    return <div className='d-flex flex-row align-items-center'>{label}</div>
  }
  const validation = useMemo(() => {
    return Object.values(paymentMethodKeys).includes(
      currentUserPaymentMethod?.__typename,
    )
  }, [currentUserPaymentMethod])

  // const client = useApolloClient()
  const writeMeQuery = (newPaymentMethod) => {
    // sobrescribe la información del usuario con el nuevo método de pago
    if (isAdmin || isParent) refetchOwnerPaymentMethod()
    else refetch()
  }
  const {data: dataPlaid} = useQuery(
    gql`
      query {
        getPlaidLink
      }
    `,
  )

  const [mutationSetState] = useMutation(
    gql`
      mutation update($PlanInputStateOnboarding: PlanInputStateOnboarding!) {
        setOnboardingState(input: $PlanInputStateOnboarding)
      }
    `,
    {
      update: () => {
        const planId = dataPlan?.idPlan || dataPlan?.id
        if (dataPlan?.isFirstPlan && !isAdmin && !isParent) {
          updateOnboardingStatus(planId, 'profile').then(
            () => {
              toggleProfile()
            },
            () => {
              toggleProfile()
            },
          )
        } else {
          const onSuccess = () => {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.mediumModal',
              data: {...mediumModal, isOpen: false},
            })
          }
          const sendWspFunction = (callbackFunction = () => {}) => {
            if (infoUser?.phone && infoUser?.phone?.trim().length > 4) {
              sendWsp({variables: {planId}}).then(
                () => {
                  callbackFunction()
                },
                (err) => {
                  showAlert({text: err.toString(), status: 'error'}, dispatch)
                  callbackFunction()
                },
              )
            }
          }
          // const sendWspFunction = (callbackFunction = () => {}) => {
          //   if (
          //     dataPlan?.isFirstPlan ||
          //     !infoUser?.phone ||
          //     infoUser?.phone?.trim().length < 4
          //   ) {
          //     // como admin
          //     // sigue en onChange de SetStudentPhoneModal
          //     openSetStudentModal((sendWspBool) => {
          //       const planId =
          //         dataPlan?.idPlan || dataPlan?.id || dataPlan?.planId
          //       if (sendWspBool)
          //         sendWsp({variables: {planId}}).then(
          //           () => {
          //             callbackFunction()
          //           },
          //           (err) => {
          //             showAlert(
          //               {text: err.toString(), status: 'error'},
          //               dispatch,
          //             )
          //             callbackFunction()
          //           },
          //         )
          //       else callbackFunction()
          //     }, dataPlan?.studentUserId || dataPlan?.studentUser?.studentUserId)
          //   } else callbackFunction()
          // }
          updateOnboardingStatus(
            dataPlan?.idPlan || dataPlan?.id,
            'approved',
          ).then(
            () => {
              sendWspFunction(() => {
                onSuccess()
              })
            },
            () => {
              sendWspFunction(() => {
                onSuccess()
              })
            },
          )
        }
      },
    },
  )
  const [
    confirmPayment,
    // eslint-disable-next-line
    {loading: loadManual, error: errorManual},
  ] = useMutation(
    gql`
      mutation update(
        $planId: ID!
        $typePayment: TypePayments!
        $paymentItemsConfig: [PaymentsItemsConfigInput!]!
        $isAdmin: Boolean
        $checkInDates: [CheckInDateInput!]!
      ) {
        approvePlanFromStudent(
          input: {
            planId: $planId
            typePayment: $typePayment
            paymentItemsConfig: $paymentItemsConfig
            checkInDates: $checkInDates
          }
          isAdmin: $isAdmin
        ) {
          success
          errorMsg
        }
      }
    `,
    {
      update: (cache, datos) => {
        if (datos.data.approvePlanFromStudent.success) {
          const onSuccess = () => {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.mainPage',
              data: {page: 'end'},
            })
            mutationSetState({
              variables: {
                PlanInputStateOnboarding: {
                  planId: dataPlan?.idPlan,
                  stateOnboarding: '',
                },
              },
            })
          }
          onSuccess()
        } else {
          setPaymentError(datos.data.approvePlanFromStudent.errorMsg)
        }
      },
    },
  )

  // mutacion para método de pago BANK TRANFER (NO USD)
  const [
    setManualPaymentMethod,
    {data: dataTranferModal, loading: loadTranferModal},
  ] = useMutation(
    gql`
      mutation update($userId: String) {
        setManualPaymentMethod(userId: $userId) {
          paymentMethod {
            ... on ManualPaymentMethod {
              kind
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
          }
        }
      }
    `,
    {
      update: (cache, {data}) => {
        // writeMeQuery(data?.setManualPaymentMethod?.paymentMethod)
        toggleTranfer()
      },
    },
  )

  // MUATICIÓN PARA MÉTODO DE PAGO BANK TRANFER (USD)
  const [
    ,
    // setBankCreditPaymentMethod
    {data: dataCreditModal, loading: loadCreditModal},
  ] = useMutation(
    gql`
      mutation update {
        setBankCreditPaymentMethod {
          ... on BankCreditPaymentMethod {
            kind
            status
            sourceInfo {
              accountNumber
              routingNumber
              bankName
              swiftCode
            }
          }
        }
      }
    `,
    {
      update: (__, {data}) => {
        writeMeQuery(data?.setBankCreditPaymentMethod)
        toggleTranferCredit()
      },
    },
  )
  // MUTACIÓN PARA CAMBIAR EL MÉTODO DE PAGO BANK DEBIT
  const [mutationSetBankDebit, {loading: loadDebitBank}] = useMutation(
    gql`
      mutation(
        $accountId: String!
        $plaidPublicToken: String!
        $userId: String
        $institutionId: String
      ) {
        setBankDebitPaymentMethod(
          accountId: $accountId
          plaidPublicToken: $plaidPublicToken
          userId: $userId
          institutionId: $institutionId
        ) {
          kind
          validationStatus
          accountInfo {
            bankName
            last4
          }
        }
      }
    `,
  )
  const mutationSetBankDebitData = ({variables}) => {
    let newVar = variables
    if (isAdmin) newVar = {...variables, userId: dataPlan.studentUserId}
    mutationSetBankDebit({variables: newVar})
      .then((result) => {
        // writeMeQuery(result?.data?.setBankDebitPaymentMethod)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const [
    setZellePaymentMethod,
    {data: dataZelleModal, loading: loadZelleModal},
  ] = useSetZellePaymentMethod(
    () => {
      toggleZelle()
      // writeMeQuery('')
    },
    (err) => {
      console.error(err)
    },
  )

  // mutation for set credit card existing
  const [
    setCreditCardDefaultPaymentMethod,
    {loading: loadingSetExistingCreditCard},
  ] = useSetCreditCardDefaultPaymentMethod(
    () => {
      showAlert(
        {
          text: 'Success',
          status: 'success',
        },
        dispatch,
      )
    },
    (error) => {
      showAlert(
        {
          text: `Error setting up manual payment: ${error.message}`,
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const [periodicity, setPeriodicity] = useState({
    value: 'recomended',
    text: translate('home.onboard.change.recomended'),
  })
  const [selectedPayments, setSelectedPayments] = useState([])
  const [isOpenProfile, setOpenProfile] = useState(false)
  const toggleProfile = () => {
    setOpenProfile(!isOpenProfile)
  }

  const [isOpenCredit, setOpenCredit] = useState(false)
  const toggleCredit = () => {
    refetchOwnerPaymentMethod()
    setOpenCredit(!isOpenCredit)
  }

  const [isOpenTranfer, setOpenTranfer] = useState(false)
  const toggleTranfer = () => {
    refetchOwnerPaymentMethod()
    setOpenTranfer(!isOpenTranfer)
  }

  const [isOpenZelle, setOpenZelle] = useState(false)
  const toggleZelle = () => {
    refetchOwnerPaymentMethod()
    setOpenZelle(!isOpenZelle)
  }

  const [isOpenTranferCredit, setOpenTranferCredit] = useState(false)
  const toggleTranferCredit = () => {
    refetchOwnerPaymentMethod()
    setOpenTranferCredit(!isOpenTranferCredit)
  }
  useEffect(() => {
    if (paymentItemsConfig && paymentItemsConfig.length) {
      setSelectedPayments(
        paymentItemsConfig.filter((p) => p.type !== 'others-fixed'),
      )
    }
  }, [paymentItemsConfig])
  const recalculatePaymentsPeriodicity = (newPeriodicity) => {
    const period = newPeriodicity.value
    const payments = paymentItemsConfig.filter((p) => p.type !== 'others-fixed')
    const from = moment(
      dataPlan?.startDate && dataPlan?.startDate?.split('T')[0],
    )
    const to = moment(dataPlan?.endDate && dataPlan?.endDate?.split('T')[0])
    const total = payments.reduce((total, pay) => {
      return total + pay.amount
    }, 0)
    let feeValue = 0
    let pay = []
    const discount =
      payments && payments.length > 0 ? payments[0].percentDiscount : 0
    // SE USA EL MISMO ALGORITMO QUE EN ADMIN PARA CONFECCIONAR LAS FECHAS DE PAGO
    const paymentsFee = GetFeeCountFromRangeDate(from, to)
    // agregar el algoritmo para cuenta de cuotas segun fecha de inicio y fin
    if (period === 'monthly') {
      for (let i = 0; i < paymentsFee.length; i++) {
        pay.push({
          amount: feeValue,
          amountWithoutDiscount: (feeValue / (100 - discount)) * 100,
          // se usan las fechas obtenidas de la función
          date: paymentsFee[i].time.toISOString(),
          description: `Payment ${i + 1}`,
          percentDiscount: discount,
          type: 'lessons-payment',
        })
      }
      feeValue = total / pay.length
      pay = pay.map((auxPay) => {
        return {
          ...auxPay,
          amount: feeValue,
          amountWithoutDiscount: (feeValue / (100 - discount)) * 100,
        }
      })
    } else if (period === 'oneTime') {
      pay = [
        {
          amount: total,
          amountWithoutDiscount: (total / (100 - discount)) * 100,
          date: paymentsFee[0].time.toISOString(),
          description: `Payment 1`,
          percentDiscount: discount,
          type: 'lessons-payment',
        },
      ]
    } else if (period === 'twoTime') {
      pay = [
        {
          amount: total / 2,
          amountWithoutDiscount: (total / 2 / (100 - discount)) * 100,
          date: paymentsFee[0].time.toISOString(),
          description: `Payment 1`,
          percentDiscount: discount,
          type: 'lessons-payment',
        },
        {
          amount: total / 2,
          amountWithoutDiscount: (total / 2 / (100 - discount)) * 100,
          date: paymentsFee[0].time.add(1, 'M').toISOString(),
          description: `Payment 2`,
          percentDiscount: discount,
          type: 'lessons-payment',
        },
      ]
    } else {
      pay = payments
    }
    setSelectedPayments(pay)
    setPeriodicity(newPeriodicity)
  }

  const isCreditCard = useMemo(() => {
    return currentUserPaymentMethod?.__typename === 'CardPaymentMethod'
  }, [currentUserPaymentMethod])
  const atApprovalAmount = useMemo(() => {
    return (paymentItemsConfig || []).reduce((amount, pay) => {
      if (pay?.type === 'others-fixed') {
        return amount + (pay?.amount || 0)
      }
      return amount
    }, 0)
  }, [paymentItemsConfig])
  return (
    <div className='m-0 p-0'>
      <div
        className={`m-0 p-0 ${
          breakWidth === 'SM' ? 'px-3' : breakWidth === 'MD' ? 'px-5' : 'px-10'
        }`}
      >
        <ChangePaymentPeriodicity
          setPeriodicity={recalculatePaymentsPeriodicity}
        >
          <ListItem
            showTopLine={false}
            showBottomLine={false}
            className='pl-0 my-2'
            Left={
              <div className='d-flex align-items-center text-muted'>
                <span>
                  <b>{translate('home.onboard.payment.payment')}</b>
                </span>
              </div>
            }
            Right={
              <span className='text-muted px-3'>
                {periodicity?.text} <IconRight />
              </span>
            }
          />
        </ChangePaymentPeriodicity>
        {dataPlaid?.getPlaidLink &&
        !loadCreditModal &&
        !loadTranferModal &&
        !loadZelleModal &&
        !loadDebitBank &&
        !loadingSetExistingCreditCard ? (
          <ChangePaymentMethod
            mutationSetBankDebitData={mutationSetBankDebitData}
            paymentMethod={paymentMethod?.value}
            setPaymentMethod={setPaymentMethod}
            keyPlaid={dataPlaid?.getPlaidLink}
            writeMeQuery={writeMeQuery}
            isAdmin={isAdmin}
            onChange={(method, action) => {
              if (method === paymentMethodKeys.BankDebit && action) action()
              else if (method === paymentMethodKeys.Card) toggleCredit()
              else if (method === paymentMethodKeys.Manual) {
                setPaymentMethod({
                  value: method,
                  text: 'manual',
                  typename: paymentMethodKeys.Manual,
                })
                setManualPaymentMethod(
                  isAdmin || isParent
                    ? {variables: {userId: dataPlan.studentUserId}}
                    : {},
                )
              } else if (method === paymentMethodKeys.Zelle) {
                setPaymentMethod({
                  value: method,
                  text: 'zelle',
                  typename: paymentMethodKeys.Zelle,
                })
                setZellePaymentMethod(
                  isAdmin || isParent
                    ? {variables: {userId: dataPlan.studentUserId}}
                    : {},
                )
              } else if (!Object.values(paymentMethodKeys).includes(method)) {
                setPaymentMethod({
                  value: method,
                  text: 'registeredCreditCard',
                  typename: paymentMethodKeys.Card,
                })
                setCreditCardDefaultPaymentMethod({
                  variables: {
                    userId: dataPlan.studentUserId,
                    paymentMethodId: method,
                  },
                })
              }
              // else if (method === paymentMethodKeys.BankCredit)
              //  setBankCreditPaymentMethod()
            }}
            registeredPaymentMethods={
              ownerPaymentMethod?.registeredPaymentMethods || []
            }
            // userId={isAdmin ? dataPlan.studentUserId : infoUser?.id}
          >
            <ListItem
              showTopLine
              showBottomLine
              className='pl-0 my-2'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <span>
                    <b>{translate('home.onboard.payment.method')}</b>
                  </span>
                </div>
              }
              Right={
                <span className='text-muted d-flex flex-row align-items-center'>
                  {paymentMethodUndefined ? (
                    <Badge
                      color='#FFEEEB'
                      className='my-auto ml-4 mr-0 px-3 mr-1 py-1 badge badge-pill badge-danger p-0'
                      pill
                    >
                      <span className='text-error d-flex flex-row align-items-center'>
                        Add <IconRight className='ml-1 text-error' />
                      </span>
                    </Badge>
                  ) : (
                    <>
                      <PaymentLabel />
                      <div className='hover-icon ml-1'>
                        <IconRight />
                      </div>
                    </>
                  )}
                </span>
              }
            />
          </ChangePaymentMethod>
        ) : (
          <ListItem
            showTopLine
            showBottomLine
            className='pl-0 my-2'
            Left={
              <div className='d-flex align-items-center text-muted'>
                <span>
                  <b>{translate('home.onboard.payment.method')}</b>
                </span>
              </div>
            }
            Right={<Activity />}
          />
        )}
        <PaymentSchedule
          data={paymentItemsConfig}
          selectedPayments={selectedPayments}
          post
        />
        <div className='borderTop' />
        <ListItem
          showTopLine={false}
          showBottomLine={false}
          className='pl-0 my-2'
          Left={
            <div className='d-flex align-items-center text-muted'>
              <span>
                <b>{translate('home.onboard.payment.total')}</b>
              </span>
            </div>
          }
          Right={
            <span>
              <b>
                $
                {formatNumber(
                  paymentItemsConfig?.reduce((total, pay) => {
                    return total + pay.amount
                  }, 0),
                )}
              </b>
            </span>
          }
        />
        {paymentError || errorManual ? (
          <span className='w-100 py-4 text-center text-error font-weight-bold'>
            {translate(
              errorManual
                ? 'modal.onboard.payment.errors.unknown'
                : paymentError,
            )}
          </span>
        ) : null}
        <StickyButton className='d-flex w-100' condition>
          <Button
            disabled={!validation || paymentMethodUndefined}
            className='w-100 mx-2'
            style={{backgroundColor: `#FFB519`, border: 'none'}}
            label={
              <span className='mr-5'>
                {isCreditCard
                  ? `${translate(
                      'counselingOnboarding.payments.body.confirmPaymentScheduledCredit',
                    )} $${atApprovalAmount} ${translate(
                      'counselingOnboarding.payments.body.confirmPaymentScheduledNow',
                    )}`
                  : // `Confirm payments and pay $${totalAmount*(4/100)} now` :
                    translate('home.onboard.payment.confirm')}
              </span>
            }
            check
            activity={loadManual || loadWsp}
            onClick={() => {
              setPaymentError(null)
              const confirmPaymentBreakdown = () => {
                confirmPayment({
                  variables: {
                    planId: dataPlan?.idPlan,
                    typePayment: periodicity?.value,
                    isAdmin,
                    paymentItemsConfig: paymentItemsConfig
                      .filter((p) => p.type === 'others-fixed')
                      .concat(selectedPayments)
                      .map(({__typename, ...p}) => {
                        if (p?.type === 'others-fixed') {
                          p.type = 'otherFixed'
                        } else p.type = 'lessonsPayment'
                        return p
                      }),
                    checkInDates: dataPlan?.checkInDates,
                  },
                })
              }

              updateOnboardingStatus(
                dataPlan?.idPlan || dataPlan?.id,
                'confirm',
              ).then(
                () => {
                  confirmPaymentBreakdown()
                },
                () => {
                  confirmPaymentBreakdown()
                },
              )
            }}
          />
        </StickyButton>

        {(process.env.LP_ONBOARDING_TERM_URL || TERMS_URL) && (
          <div className='w-100 d-flex flex-row mx-0 my-2 px-0 py-2 justify-content-start'>
            <label className='text-gray'>
              {`${translate('modal.onboard.payment.iAccept')} `}
              <span
                onClick={() => {
                  window
                    .open(
                      process.env.LP_ONBOARDING_TERM_URL || TERMS_URL,
                      '_blank',
                    )
                    .focus()
                }}
                className='btn-link'
              >
                {translate('modal.onboard.payment.terms')}
              </span>
            </label>
          </div>
        )}

        <CompleteProfile isOpen={isOpenProfile} toggle={toggleProfile} />
        <CreditCardModal
          refetchOwner={refetchOwnerPaymentMethod}
          isOpen={isOpenCredit}
          toggle={toggleCredit}
          paymentMethod={paymentMethod}
          writeMeQuery={writeMeQuery}
          setPaymentMethod={setPaymentMethod}
          isAdmin={isAdmin}
          isParent={isParent}
          registeredPaymentMethods={
            ownerPaymentMethod?.registeredPaymentMethods || []
          }
        />
        <BankTranferModal
          isOpen={isOpenTranfer}
          toggle={toggleTranfer}
          bankData={
            !loadTranferModal
              ? dataTranferModal?.setManualPaymentMethod?.paymentMethod
                  ?.manualPaymentInfo
              : {}
          }
          writeMeQuery={writeMeQuery}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        <ZelleModal
          isOpen={isOpenZelle}
          toggle={toggleZelle}
          recipient={
            dataZelleModal?.setZellePaymentMethod?.paymentMethod
              ?.zellePaymentInfo?.emailToSend
          }
          writeMeQuery={writeMeQuery}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        <BankCreditModal
          isOpen={isOpenTranferCredit}
          toggle={toggleTranferCredit}
          bankData={
            !loadCreditModal
              ? dataCreditModal?.setBankCreditPaymentMethod?.sourceInfo
              : {}
          }
          writeMeQuery={writeMeQuery}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        {/* <SetStudentPhoneModal /> */}
      </div>
    </div>
  )
}
