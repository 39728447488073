import React from 'react'


const ParentView = () => (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <p style={{ fontSize: '2rem', fontWeight: 'bold' }}>COMING SOON</p>
    </div>
  );
  
export default ParentView
