import React, {useEffect, useMemo, useState} from 'react'
import {
  ModalLayout,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useModalState from '../../../../../hooks/useModalState'
import {useLazyQuery} from '@apollo/client'
import invoiceQuery from '../query'
import invoiceQueryOffline from '../queryOffline'
import Header from './Header'
import Submit from './Submit'
import Details from './Details'

const InvoiceModal = (props) => {
  const {refetch, offline = false} = props
  const {isOpen: modalOpen, modalData, toggle: toggleState} = useModalState(
    'payments.viewInvoice',
  )
  const {breakWidth} = useWindowDimensions()
  const {invoiceId} = modalData
  const [
    getInvoice,
    {data: invoiceData, loading: invoiceLoading, refetch: invoiceRefetch},
  ] = useLazyQuery(offline ? invoiceQueryOffline : invoiceQuery, {
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (invoiceId && invoiceId !== '' && typeof invoiceId === 'string') {
      getInvoice({variables: {invoiceId}})
    }
  }, [invoiceId, getInvoice])
  const {invoice: invoiceOnline, getInvoiceOffline} = invoiceData ?? {
    invoice: {paymentItems: [], amounts: {}},
  }
  const invoice = offline ? getInvoiceOffline || {} : invoiceOnline || {}
  // se puede sobreescribir el valor de colorState para cambiar el color de la cabecera
  const [colorState, setColorState] = useState('white') // [gray,white,green,blue,darkBlue,red]
  // lo que hace es tomar usar el color para reemplazarlo por una clase css
  // que puede ser modalState: [white, canceled, pendingPayment,
  //   paid, upcoming, counceling, error, draft]
  const colors = {
    gray: 'canceled',
    white: 'white',
    green: 'paid',
    blue: 'upcoming',
    darkBlue: 'counceling',
    red: 'error',
  }
  useEffect(() => {
    if (offline) {
      if (invoice?.category === 'livePrep') setColorState('blue')
      else setColorState('darkBlue')
    } else {
      switch (invoice?.status) {
        case 'draft':
          setColorState('white')
          break
        case 'pendingPayment':
          if (invoice.category === 'livePrep') {
            setColorState('blue')
          } else {
            setColorState('darkBlue')
          }
          break
        case 'procesingPayment':
          if (moment(invoice.to) < moment()) {
            setColorState('red')
          } else {
            setColorState('blue')
          }
          break
        case 'paid':
          setColorState('green')
          break
        case 'unPaid':
          setColorState('red')
          break
        case 'canceled':
          setColorState('gray')
          break
        default:
          setColorState('white')
          break
      }
    }
  }, [invoice, offline])

  // colorState = 'gray'
  const modalState = useMemo(() => {
    return colors[colorState]
  }, [colorState, colors])

  // colors
  // gray: canceled,
  // white: white, pendingPayment, draft
  // green: paid
  // blue: upcoming
  // darkBlue: counceling
  // red: error
  const offDimensions = useMemo(() => offline, [offline])
  const toggle = () => {
    if (offline) return
    toggleState()
  }

  return (
    <ModalLayout
      buttonLabel={null}
      contentClassName={`${offline ? 'gray-border-offline-invoice' : ''} ${
        offline && breakWidth === 'XL'
          ? 'adjust-width-xl-offline-invoice'
          : offline && breakWidth === 'MD'
          ? 'adjust-width-md-offline-invoice'
          : ''
      }`}
      // modal-dialog modal-sticky modal-colored modal-botbar upcoming-modal px-7 mx-10 modal-border-radius
      className={`modal-colored modal-${
        breakWidth === 'SM' || offDimensions ? 'botbar' : 'sidebar'
      } ${modalState}-modal ${
        breakWidth !== 'SM' && offDimensions
          ? `${offline && breakWidth === 'MD' ? 'px-0' : 'px-7'} mx-10`
          : ''
      } ${offDimensions ? 'modal-border-radius' : ''}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      disabledScrollAnimation
      isOpen={modalOpen}
      toggle={toggle}
      modalHeaderClassName='px-6 px-sm-8 px-lg-10 px-xl-10 align-items-center'
      dimensionClassName='m-0 px-0 py-3'
      topHead={
        <span className='h3 text-white'>
          {offline ? (
            <span>{moment.utc(invoice.from).format('MMMM')}</span>
          ) : (
            <>
              <span>Next pay : </span>{' '}
              <span>{`End of ${moment.utc(invoice.to).format('MMMM')}`}</span>
            </>
          )}
        </span>
      }
      minHeadClassName='pl-4 pl-sm-6 pl-lg-8 pl-xl-8 align-items-center'
      minHead={
        <span className='h3 text-white pl-6'>
          {offline ? (
            <span>{moment.utc(invoice.from).format('MMMM')}</span>
          ) : (
            <>
              <span>Next pay : </span>{' '}
              <span>{`End of ${moment.utc(invoice.to).format('MMMM')}`}</span>
            </>
          )}
          {/* <b>Next pay : </b>{' '}
          <span>{`End of ${moment.utc(invoice.to).format('MMMM')}`}</span> */}
        </span>
      }
      underHead={
        <Header
          offline={offline}
          className='w-100'
          invoice={invoice || {}}
          loading={invoiceLoading || !Object.keys(invoice).length}
          toggle={toggle}
          modalState={modalState === 'upcoming' ? 'live' : modalState}
        />
      }
      // Head={
      // <Header
      //   invoice={invoice || {}}
      //   loading={invoiceLoading || !Object.keys(invoice).length}
      //   toggle={toggle}
      //   modalState={modalState === 'upcoming' ? 'live' : modalState}
      // />
      // }
    >
      {/* px-4 px-sm-6 px-lg-8 px-xl-10 */}
      <div className='pt-4 pt-md-6 pt-lg-7 px-6 px-sm-8 px-lg-10 px-xl-10 d-flex flex-column'>
        <Details
          offline={offline}
          invoice={invoice || {}}
          loading={invoiceLoading || !Object.keys(invoice).length}
        />
        <div />
        <Submit
          offline={offline}
          buttonStyle={{
            position: 'sticky',
            bottom: '10px',
          }}
          invoice={invoice || {}}
          loading={invoiceLoading || !Object.keys(invoice).length}
          refetch={() => {
            refetch()
            invoiceRefetch({variables: {invoiceId}})
          }}
        />
      </div>
    </ModalLayout>
  )
}

export default InvoiceModal
