// Recibe el array que se pasa en el VirtualChat, que es un array
// de objetos con los integrantes del team y un objeto con los avatares
import rolesNames from './rolesNames'

export const setAvatarToUsers = (usersList) => {
  if (!Array.isArray(usersList) || usersList.length === 0) return [];

  return usersList
    .filter((user) => user?.id)
    .map((user) => ({
      ...user,
      avatar: user.avatar
        ? usersList[3][
            `avatar${
              rolesNames[user.role][0].toUpperCase() +
              rolesNames[user.role].substring(1)
            }`
          ]
        : user.avatar,
    }));
};
